import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Grid } from '../../../components/mui';
import KeyValueList from '../../../components/KeyValueList';
import { useNodes } from '../../nodes/hooks';
import { handleError } from '../../../utils/handleError';
import { getSelectedNodes } from '../../nodes/selectors';

const itemDefs = [
    {
        key: 'name',
        label: 'Name',
        value: 'nodeInfo.name',
    },
    {
        key: 'nodeStatus',
        label: 'Node Status',
        value: 'nodeInfo.phase',
    },
];

const NodesList = ({ items }) => {
    const { data } = useNodes({
        onError: (err) => toast.error(handleError(err)),
    });

    const nodes = getSelectedNodes(
        data,
        items.map((item) => item.node),
    );

    const mappedItems = items.map((item) => ({
        ...item,
        nodeInfo: nodes.find((node) => node.name === item.node),
    }));

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <KeyValueList items={mappedItems} itemDefinitions={itemDefs} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8} />
        </Grid>
    );
};

NodesList.propTypes = {
    items: PropTypes.array,
};

NodesList.defaultProps = {
    items: [],
};

export default NodesList;
