import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '../../../components/mui';
import KeyValueList from '../../../components/KeyValueList';

const itemDefs = [
    {
        key: 'image',
        label: 'Base Image',
        value: 'image',
    },
    {
        key: 'cpu',
        label: 'CPU',
        value: 'cpu',
    },
    {
        key: 'memory',
        label: 'Memory (G)',
        itemContentRenderer: (item) =>
            item.memory ? `${item.memory} G` : 'auto',
    },
    {
        key: 'iso',
        label: 'ISO',
        value: 'iso',
    },
    {
        key: 'vnc',
        label: 'VNC',
        itemContentRenderer: (item) =>
            `${item.vncConsole ? 'enabled' : 'disabled'}`,
    },
    {
        key: 'gpu',
        label: 'GPU',
        itemContentRenderer: (item) =>
            `${item.gpuPassthrough ? 'enabled' : 'disabled'}`,
    },
    {
        key: 'owner',
        label: 'Owner',
        value: 'owner',
    },
];

const VmConfigsList = ({ items }) => (
    <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4}>
            <KeyValueList items={items} itemDefinitions={itemDefs} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8} />
    </Grid>
);

VmConfigsList.propTypes = {
    items: PropTypes.array,
};

VmConfigsList.defaultProps = {
    items: [],
};

export default VmConfigsList;
