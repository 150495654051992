import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get, noop } from 'lodash';

import Dialog from '../../../components/Dialog';
import GenerateImageForm from './GenerateImageForm';
import { handleError } from '../../../utils/handleError';
import { handleSuccess } from '../../../utils/handleSuccess';
import { messages } from '../constants';
import { useImages, useGenerateImage } from '../hooks';

const { msgGenerate, msgGenerated } = messages;

const GenerateImageDialog = ({ isOpen, onClose }) => {
    const queryClient = useQueryClient();

    // queries
    const { data: imagesData } = useImages({
        onError: (err) => toast.error(handleError(err)),
    });

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('images');
        },
    };
    const { mutate: mutateGenerateImage, isLoading } = useGenerateImage({
        ...mutateProps,
        onSuccess: (res) => {
            onClose();
            toast.success(handleSuccess(res, msgGenerated));
        },
    });

    // actions
    const handleGenerateImage = async ({
        fileName,
        fileSize,
        selectedUnit,
    }) => {
        await mutateGenerateImage({
            fileName,
            fileSize: `${fileSize}${selectedUnit}`,
        });
    };

    const handleClose = () => {
        onClose();
    };

    const images = get(imagesData, 'items', []).map((image) => image.name);

    return (
        <Dialog
            isSubmitting={isLoading}
            isOpen={isOpen}
            title={`${msgGenerate} Image`}
            onClose={handleClose}
        >
            <GenerateImageForm
                images={images}
                onClose={handleClose}
                onSubmit={handleGenerateImage}
                isSubmitting={isLoading}
            />
        </Dialog>
    );
};

GenerateImageDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

GenerateImageDialog.defaultProps = {
    isOpen: false,
    onClose: noop,
};

export default GenerateImageDialog;
