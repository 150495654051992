import React from 'react';
import { get } from 'lodash';
import { useApiVersion } from '../hooks';

import { Typography } from '../../components/mui';

const ApiVersion = () => {
    const { data } = useApiVersion();

    return (
        <Typography variant="caption" color="inherit">
            API Version: {get(data, 'info.version', 'N/A')}
        </Typography>
    );
};

export default ApiVersion;
