import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get, noop } from 'lodash';

import Dialog from '../../../components/Dialog';
import CopyIsoForm from './CopyIsoForm';
import { handleError } from '../../../utils/handleError';
import { handleSuccess } from '../../../utils/handleSuccess';
import { messages } from '../constants';
import { useCopyIso } from '../hooks';

const { msgCopy, msgCopied } = messages;

const CopyIsoDialog = ({ selectedItem, isOpen, onClose }) => {
    const queryClient = useQueryClient();

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('isos');
        },
    };
    const { mutate: mutateCopyImage, isLoading } = useCopyIso({
        ...mutateProps,
        onSuccess: (res) => {
            onClose();
            toast.success(handleSuccess(res, msgCopied));
        },
    });

    // actions
    const handleCopyImage = async ({ newName }) => {
        await mutateCopyImage({
            iso: get(selectedItem, 'name', ''),
            newName,
        });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            isSubmitting={isLoading}
            isOpen={isOpen}
            title={`${msgCopy} ISO`}
            onClose={handleClose}
        >
            <CopyIsoForm
                selectedItem={selectedItem}
                onClose={handleClose}
                onSubmit={handleCopyImage}
            />
        </Dialog>
    );
};

CopyIsoDialog.propTypes = {
    selectedItem: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

CopyIsoDialog.defaultProps = {
    selectedItem: null,
    isOpen: false,
    onClose: noop,
};

export default CopyIsoDialog;
