import React from 'react';
import { formatGpu } from './formatGpu';
import Status from '../pages/components/Status';

export const addNodeGPUColumn = (gpuEnabled, itemDefs) => {
    const itemDefinitions = itemDefs.slice();
    if (gpuEnabled) {
        itemDefinitions.splice(4, 0, {
            key: 'summaryGpu',
            label: 'Available GPU',
            itemContentRenderer: (item) =>
                `${formatGpu(item.availableGpu)} / ${formatGpu(
                    item.allocatableGpu,
                )}`,
        });
    }
    return itemDefinitions;
};

export const addVMConfigsGPUColumn = (gpuEnabled, itemDefs) => {
    const itemDefinitions = itemDefs.slice();
    if (gpuEnabled) {
        itemDefinitions.splice(5, 0, {
            key: 'summaryGpu',
            label: 'GPU',
            itemContentRenderer: (item) => {
                const state = item.gpuPassthrough ? 'enabled' : 'disabled';
                const status = item.gpuPassthrough ? 'success' : 'warning';
                const title = item.gpuPassthrough
                    ? 'Enable GPU passthrough on VM'
                    : 'Disable GPU passthrough on VM';
                return <Status title={title} label={state} variant={status} />;
            },
        });
    }
    return itemDefinitions;
};
