import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Grid, Tooltip, Typography } from '../../../components/mui';
import { addVMGPURows } from '../../../utils/addGPURows';
import KeyValueList from '../../../components/KeyValueList';
import {
    getTitle as getStatusTitle,
    getLabel as getStatusLabel,
} from './VmsTable';
import { formatDate } from '../../../utils/formatDate';

const itemDefs = [
    {
        key: 'name',
        label: 'Name',
        value: 'name',
    },
    {
        key: 'status',
        label: 'Status',
        itemContentRenderer: (item) => {
            const status = get(item, 'status', '');

            return (
                <Tooltip title={getStatusTitle(status)}>
                    <Typography component="span" variant="body2">
                        {getStatusLabel(status)}
                    </Typography>
                </Tooltip>
            );
        },
    },
    {
        key: 'ip',
        label: 'Address',
        value: 'ip',
    },
    {
        key: 'image',
        label: 'Base Image',
        value: 'image',
    },
    {
        key: 'cpu',
        label: 'CPU',
        value: 'cpu',
    },
    {
        key: 'memory',
        label: 'RAM',
        value: 'memory',
    },
    {
        key: 'vnc',
        label: 'VNC Port',
        value: 'vnc',
    },
    {
        key: 'screenshare',
        label: 'Screen Sharing Port',
        value: 'screenshare',
    },
    {
        key: 'ssh',
        label: 'SSH Port',
        value: 'ssh',
    },
    {
        key: 'owner',
        label: 'Owner',
        value: 'owner',
    },
    {
        key: 'deployDate',
        label: 'Deployed',
        itemContentRenderer: (item) => formatDate(item.deployDate),
    },
];

const VmsList = ({ items, gpuEnabled }) => {
    const itemDefinitions = addVMGPURows(gpuEnabled, itemDefs);
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <KeyValueList items={items} itemDefinitions={itemDefinitions} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8} />
        </Grid>
    );
};

VmsList.propTypes = {
    items: PropTypes.array,
    gpuEnabled: PropTypes.bool,
};

VmsList.defaultProps = {
    items: [],
    gpuEnabled: false,
};

export default VmsList;
