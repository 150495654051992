const passwordLength = 6;
const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const validators = {
    isValidEmail: (value) => regexEmail.test(value),
    isValidPassword: (value) => value.length >= passwordLength,
};

export const messages = {
    msgRequired: '* Required field.',
    msgInvalidEmail: 'Invalid email.',
    msgInvalidPassword: `Invalid password. Password must be at least ${passwordLength} characters long.`,
    msgInvalidConfirmPassword: 'Password does not match.',
    msgNetworkError: 'Network error.',
    msgCreate: 'Create',
    msgLogin: 'Log in',
    msgLogout: 'Log out',
    msgUpdate: 'Update',
    msgCancel: 'Cancel',
    msgRefresh: 'Refresh',
    msgSave: 'Save',
    msgView: 'View Details',
    msgMore: 'More',
    msgMoreDetails: 'More Actions',
    msgBack: 'Back',
    msgLongerOperationHint: `This operation might take a while.`,
    msgSupportTicket: 'Support ticket',
    msgDocumentation: 'Documentation',
};

export const textFields = {
    txtAvailableGPUHeader: 'Available GPU',
    txtAllocatableGPUHeader: 'Allocatable GPU',
};

export const defaultNamespace = 'orka-default';

export const baseAPIUrl = `/api/v1/namespaces/${defaultNamespace}`;

export const clusterInfoUrl = '/api/v1/cluster-info';

export const armArchitecture = 'arm64';

export const amdArchitecture = 'amd64';
