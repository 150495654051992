/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { camelCase, isEmpty } from 'lodash';

import { Box, Grid } from '../../../components/mui';
import Main from '../../../components/Main';
import FeatureSection from '../marketing/FeatureSection';
import HeroSection from '../marketing/HeroSection';
import gradients from '../../../theme/gradients';
import { ReactComponent as StormBrightGradient } from '../../../svg/stormBrightGradient.svg';
import { ReactComponent as OceanBrightGradient } from '../../../svg/oceanBrightGradient.svg';
import { ReactComponent as FireBrightGradient } from '../../../svg/fireBrightGradient.svg';

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
    wrapper: {
        margin: spacing(-5),
        [breakpoints.down('sm')]: {
            marginLeft: spacing(-2),
            marginRight: spacing(-2),
        },
    },
    hero: {
        marginTop: spacing(-7),
        [breakpoints.up('md')]: {
            paddingLeft: typography.pxToRem(100),
            paddingRight: typography.pxToRem(100),
            background: ({ customTheme }) =>
                customTheme ? gradients[customTheme] : gradients.oceanBright,
        },
    },
    feature: {
        marginTop: spacing(1),
        [breakpoints.up('md')]: {
            paddingLeft: typography.pxToRem(100),
            paddingRight: typography.pxToRem(100),
        },
    },
}));

const DiscoveryLayout = ({ heroSectionConfig, featuresSectionConfig }) => {
    const props = { customTheme: camelCase(heroSectionConfig.theme) };
    const classes = useStyles(props);

    return (
        <Box className={cx('marketing', classes.wrapper)}>
            <Main>
                <Grid container className={classes.hero} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        {heroSectionConfig && (
                            <HeroSection config={heroSectionConfig} />
                        )}
                    </Grid>
                </Grid>

                <Grid container className={classes.feature} spacing={2}>
                    {!isEmpty(featuresSectionConfig) &&
                        featuresSectionConfig.map((item, index) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                className="py-3"
                            >
                                <FeatureSection config={item} />
                            </Grid>
                        ))}
                </Grid>
            </Main>
            <StormBrightGradient />
            <OceanBrightGradient />
            <FireBrightGradient />
        </Box>
    );
};

DiscoveryLayout.propTypes = {
    heroSectionConfig: PropTypes.shape({
        icon: PropTypes.string,
        theme: PropTypes.string,
        title: PropTypes.array,
        description: PropTypes.array,
        ctaDescription: PropTypes.array,
        ctaLabel: PropTypes.string,
        ctaLink: PropTypes.string,
    }).isRequired,
    featuresSectionConfig: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            theme: PropTypes.string,
            title: PropTypes.array,
            description: PropTypes.array,
        }),
    ),
};

DiscoveryLayout.defaultProps = {
    featuresSectionConfig: [],
};

export default DiscoveryLayout;
