import React, { forwardRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

// A link that is active when itself or deeper routes are current
// It works with both absolute and relative paths
const PartiallyActiveNavLink = forwardRef(
    ({ to, className, activeClass, children, ...rest }, ref) => {
        const { pathname } = useResolvedPath(to);
        const isPartiallyActive = useMatch(`${pathname}/*`);

        return (
            <Link
                ref={ref}
                to={to}
                className={cx(className, {
                    [activeClass]: isPartiallyActive,
                    active: isPartiallyActive,
                })}
                {...rest}
            >
                {children}
            </Link>
        );
    },
);

PartiallyActiveNavLink.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    activeClass: PropTypes.string,
    children: PropTypes.node.isRequired,
};

PartiallyActiveNavLink.defaultProps = {
    className: undefined,
    activeClass: undefined,
};

export default PartiallyActiveNavLink;
