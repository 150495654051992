import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { get, noop } from 'lodash';

import {
    Paper,
    MuiTable,
    TableHead,
    TableBody,
    TableRow,
    MuiTableCell,
} from './mui';

import {
    itemDefinitionsPropTypes,
    itemsDefaultProps,
    itemDefinitionsDefaultProps,
} from './propTypes';
import { formatAny } from '../utils/formatAny';

const useStyles = makeStyles({
    header: {
        fontWeight: 'bold',
    },
});

const Table = ({
    items,
    itemDefinitions,
    primaryKey,
    selectedItem,
    onSelect,
}) => {
    const classes = useStyles();

    const isSelected = (item) => {
        if (!selectedItem) {
            return false;
        }

        return get(item, primaryKey) === get(selectedItem, primaryKey);
    };

    const handleClick = (item) => {
        onSelect(isSelected(item) ? null : item);
    };

    return (
        <Paper elevation={1}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {itemDefinitions &&
                            itemDefinitions.map(({ key, label }) => (
                                <MuiTableCell
                                    key={key}
                                    className={classes.header}
                                >
                                    {label}
                                </MuiTableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items &&
                        items.map((item, index) => (
                            <TableRow
                                hover
                                key={get(item, primaryKey, index)}
                                onClick={() => handleClick(item)}
                                selected={isSelected(item)}
                            >
                                {itemDefinitions &&
                                    itemDefinitions.map(
                                        ({
                                            key,
                                            value,
                                            className,
                                            itemContentRenderer = (i) =>
                                                formatAny(get(i, value)),
                                        }) => (
                                            <MuiTableCell
                                                key={key}
                                                className={className}
                                            >
                                                {itemContentRenderer(item)}
                                            </MuiTableCell>
                                        ),
                                    )}
                            </TableRow>
                        ))}
                </TableBody>
            </MuiTable>
        </Paper>
    );
};

Table.propTypes = {
    primaryKey: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    itemDefinitions: itemDefinitionsPropTypes,
    selectedItem: PropTypes.object,
    onSelect: PropTypes.func,
};

Table.defaultProps = {
    primaryKey: 'name',
    items: itemsDefaultProps,
    itemDefinitions: itemDefinitionsDefaultProps,
    selectedItem: null,
    onSelect: noop,
};

export default Table;
