import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { MuiButton, IconButton, Tooltip } from '../../components/mui';

const Button = ({ onClick, isIcon, title, children, ...rest }) => (
    <Tooltip title={title}>
        <span>
            {isIcon ? (
                <IconButton onClick={onClick} {...rest}>
                    {children}
                </IconButton>
            ) : (
                <MuiButton variant="contained" onClick={onClick} {...rest}>
                    {children}
                </MuiButton>
            )}
        </span>
    </Tooltip>
);

Button.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    isIcon: PropTypes.bool,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    children: 'Button',
    title: '',
    isIcon: false,
    onClick: noop,
};

export default Button;
