import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import { Box } from '../../components/mui';

const Hint = ({ children, variant, fullWidth }) => (
    <Box mt={3} display={fullWidth ? 'block' : 'flex'}>
        <Alert severity={variant}>{children}</Alert>
    </Box>
);

Hint.propTypes = {
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    variant: PropTypes.oneOf(['info', 'success', 'error', 'warning']),
};

Hint.defaultProps = {
    children: null,
    fullWidth: false,
    variant: 'info',
};

export default Hint;
