import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

import { Box } from '../../../components/mui';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Main from '../../../components/Main';
import Sidebar from '../../../components/Sidebar';
import UserMenu from '../UserMenu';
import HelpMenu from '../HelpMenu';
import SidebarNav from '../../../components/SidebarNav';
import ApiVersion from '../ApiVersion';
import navItems from '../../navItems';

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
});

const Layout = ({ headerContent, sidebarContent, sidebarFooterContent }) => {
    const classes = useStyles();

    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => setMenuOpen(!isMenuOpen);

    return (
        <div className={classes.root}>
            <Header
                onMenuToggle={handleMenuToggle}
                headerContent={headerContent}
            />
            <Sidebar
                isMenuOpen={isMenuOpen}
                onMenuToggle={handleMenuToggle}
                sidebarContent={sidebarContent}
                sidebarFooterContent={sidebarFooterContent}
            />
            <Main>
                <Outlet />
            </Main>
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    headerContent: PropTypes.node,
    sidebarContent: PropTypes.node,
    sidebarFooterContent: PropTypes.node,
};

Layout.defaultProps = {
    headerContent: (
        <Box display="flex" alignItems="center">
            <HelpMenu />
            <UserMenu />
        </Box>
    ),
    sidebarContent: <SidebarNav items={navItems} />,
    sidebarFooterContent: <ApiVersion />,
};

export default Layout;
