/* eslint react/no-array-index-key: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import cx from 'classnames';

import { List, ListItem, ListItemText, Typography, Grid } from './mui';
import {
    itemDefinitionsPropTypes,
    itemsDefaultProps,
    itemDefinitionsDefaultProps,
} from './propTypes';
import { formatAny } from '../utils/formatAny';

const useStyles = makeStyles({
    header: {
        fontWeight: 'bold',
    },
    content: {
        wordBreak: 'break-word',
    },
});

const KeyValueList = ({ items, itemDefinitions }) => {
    const classes = useStyles();

    return (
        items &&
        items.map((item, index) => (
            <List key={index}>
                {itemDefinitions &&
                    itemDefinitions.map(
                        ({
                            key,
                            value,
                            label,
                            className,
                            itemContentRenderer = (i) =>
                                formatAny(get(i, value)),
                        }) => (
                            <ListItem dense key={key}>
                                <ListItemText
                                    key={key}
                                    className={className}
                                    disableTypography
                                    primary={
                                        <Grid container spacing={3}>
                                            <Grid
                                                container
                                                item
                                                justifyContent="flex-end"
                                                xs
                                            >
                                                <Typography
                                                    className={classes.header}
                                                    variant="body2"
                                                >{`${label}:`}</Typography>
                                            </Grid>

                                            <Grid item xs>
                                                <Typography
                                                    variant="body2"
                                                    className={cx(
                                                        className,
                                                        classes.content,
                                                    )}
                                                >
                                                    {itemContentRenderer(item)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        ),
                    )}
            </List>
        ))
    );
};

KeyValueList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    itemDefinitions: itemDefinitionsPropTypes,
};

KeyValueList.defaultProps = {
    items: itemsDefaultProps,
    itemDefinitions: itemDefinitionsDefaultProps,
};

export default KeyValueList;
