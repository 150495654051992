import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { formatAny } from '../../../utils/formatAny';

import Table from '../../../components/Table';
import Status from '../../components/Status';
import { addVMConfigsGPUColumn } from '../../../utils/addGPUColumn';

const itemDefs = [
    {
        key: 'name',
        label: 'Name',
        value: 'name',
    },
    {
        key: 'image',
        label: 'Base Image',
        value: 'image',
    },
    {
        key: 'summaryCpu',
        label: 'CPU',
        itemContentRenderer: (item) => `${formatAny(item.cpu)}`,
    },
    {
        key: 'memory',
        label: 'Memory (G)',
        itemContentRenderer: (item) =>
            item.memory ? `${item.memory} G` : 'automatic G',
    },
    {
        key: 'iso',
        label: 'ISO',
        itemContentRenderer: (item) => (item.iso ? `${item.iso}` : 'None'),
    },
    {
        key: 'vnc',
        label: 'VNC',
        itemContentRenderer: (item) => {
            const state = item.vncConsole === false ? 'disabled' : 'enabled';
            const status = item.vncConsole === false ? 'warning' : 'success';
            const title =
                item.vncConsole === false
                    ? 'Disable VNC console on VM'
                    : 'Enable VNC console on VM';
            return <Status title={title} label={state} variant={status} />;
        },
    },
    {
        key: 'netBoost',
        label: 'Net Boost',
        itemContentRenderer: (item) => {
            const state = item.netBoost ? 'enabled' : 'disabled';
            const status = item.netBoost ? 'success' : 'warning';
            const title = item.netBoost
                ? 'Enable Net boost on VM'
                : 'Disable Net boost on VM';
            return <Status title={title} label={state} variant={status} />;
        },
    },
];

const VmConfigsTable = ({ items, selectedItem, onSelect, gpuEnabled }) => {
    const itemDefinitions = addVMConfigsGPUColumn(gpuEnabled, itemDefs);
    return (
        <Table
            primaryKey="name"
            items={items}
            itemDefinitions={itemDefinitions}
            selectedItem={selectedItem}
            onSelect={onSelect}
        />
    );
};

VmConfigsTable.propTypes = {
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    onSelect: PropTypes.func,
    gpuEnabled: PropTypes.bool,
};

VmConfigsTable.defaultProps = {
    items: [],
    selectedItem: null,
    onSelect: noop,
    gpuEnabled: false,
};

export default VmConfigsTable;
