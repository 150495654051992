import React from 'react';

import RegisterForm from './components/RegisterForm';
import Error from '../../components/Error';
import { handleError } from '../../utils/handleError';
import useUser from '../../context/useUser';

const Register = () => {
    const {
        createUser: { mutate: mutateCreateUser, isError, error },
    } = useUser();

    const handleSubmit = (values) => {
        mutateCreateUser(values);
    };

    return (
        <RegisterForm onSubmit={handleSubmit}>
            {isError && <Error message={handleError(error)} />}
        </RegisterForm>
    );
};

export default Register;
