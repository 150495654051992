import { useContext } from 'react';

import ConfirmContext from './ConfirmContext';

const useConfirm = () => {
    const context = useContext(ConfirmContext);

    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error('useConfirm must be used within a ConfirmProvider.');
    }
    return context;
};

export default useConfirm;
