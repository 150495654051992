import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { get, noop, includes } from 'lodash';

import {
    Grid,
    MuiButton,
    Box,
    CircularProgress,
    Typography,
    Tooltip,
    DialogContentText,
} from '../../../components/mui';
import Form from '../../../components/Form';
import useConfirm from '../../../context/useConfirm';
import { messages as commonMessages } from '../../constants';
import { messages } from '../constants';
import { UNITS } from '../enums';
import { formatHtml } from '../../../utils/formatHtml';

const { msgRequired, msgCancel } = commonMessages;
const { msgGenerate, msgConfirmOverwrite } = messages;

const units = [
    {
        name: UNITS.K,
        title: 'kilobytes',
    },
    {
        name: UNITS.M,
        title: 'megabytes',
    },
    {
        name: UNITS.G,
        title: 'gigabytes',
    },
    {
        name: UNITS.T,
        title: 'terabytes',
    },
];

const GenerateImageForm = ({ images, onSubmit, onClose, isSubmitting }) => {
    const confirm = useConfirm();
    const [selectedUnit, setSelectedUnit] = useState(UNITS.G);

    const handleUnitChange = (_, unit) => {
        setSelectedUnit(unit);
    };

    const fields = [
        {
            name: 'fileName',
            label: 'Name *',
            initialValue: '',
        },
        {
            name: 'fileSize',
            label: 'Size *',
            type: 'number',
            initialValue: '',
            inputProps: {
                min: 0,
            },
            fieldContent: (
                <ToggleButtonGroup
                    exclusive
                    data-testid="units"
                    onChange={handleUnitChange}
                    aria-label="image size units"
                >
                    {units.map(({ name, title }) => (
                        <Tooltip key={name} title={title}>
                            <ToggleButton
                                value={name}
                                aria-label={name}
                                selected={selectedUnit === name}
                            >
                                <Typography variant="caption">
                                    {name}
                                </Typography>
                            </ToggleButton>
                        </Tooltip>
                    ))}
                </ToggleButtonGroup>
            ),
        },
    ];

    const handleValidate = (values) => {
        const errors = {};

        if (!values.fileName) {
            errors.fileName = msgRequired;
        }

        if (!values.fileSize) {
            errors.fileSize = msgRequired;
        }

        return errors;
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = async (values) => {
        let isConfirmed = true;

        const imageName = get(values, 'fileName', '');

        if (includes(images, imageName)) {
            isConfirmed = await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmOverwrite(imageName),
                        )}
                    />
                ),
            });
        }

        if (isConfirmed) {
            await onSubmit({
                ...values,
                selectedUnit,
            });
        }
    };

    const formContentRenderer = () => (
        <Box mt={2} mb={1}>
            <Grid container alignItems="center">
                <Grid item xs>
                    {isSubmitting && (
                        <Box data-testid="loading">
                            <CircularProgress size="1.5rem" />
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <MuiButton
                        data-testid="cancel"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        {msgCancel}
                    </MuiButton>
                    <MuiButton
                        data-testid="submit"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {msgGenerate}
                    </MuiButton>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
        />
    );
};

GenerateImageForm.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

GenerateImageForm.defaultProps = {
    images: [],
    onSubmit: noop,
    onClose: noop,
    isSubmitting: false,
};

export default GenerateImageForm;
