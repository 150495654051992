/**
 * @param {string} ipAddrStr - ipv4 address
 * @example
 * ipv4ToNum('1.2.3.4'); // 168427520
 * @returns {number} 32-bit Number
 */
export const ipv4ToNum = (ipAddrStr) => {
    const ipv4OctetSignificance = [16777216, 65536, 256, 1];

    return (
        ipAddrStr &&
        ipAddrStr
            .split('.')
            .map((octet, i) => Number(octet) * ipv4OctetSignificance[i])
            .reduce((a, b) => a + b)
    );
};

/**
 * @param {string} ipv4 - ipv4 address
 * @example
 * formatIpv4('5.10.20.100'); // '  5. 10. 20.100'
 * @returns {string} fixed width ip octets
 */
export const formatIpv4 = (ipv4) =>
    ipv4 &&
    ipv4
        .split('.')
        .map((octet) => octet.padStart(3, ' '))
        .join('.');
