import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';

import { MuiCard } from './mui';

const useStyles = makeStyles(({ palette, typography }) => ({
    root: {
        border: `1px solid currentColor`,

        '&.success': {
            backgroundColor: lighten(palette.success.main, 0.9),
            borderColor: palette.success.main,
        },

        '&.error': {
            backgroundColor: lighten(palette.error.main, 0.9),
            borderColor: palette.error.main,
        },

        '&.warning': {
            backgroundColor: lighten(palette.warning.main, 0.9),
            borderColor: palette.warning.main,
        },
    },
    indicator: {
        height: typography.pxToRem(10),
        minWidth: typography.pxToRem(10),
    },
}));

const Card = ({ variant, children, ...rest }) => {
    const classes = useStyles();

    return (
        <MuiCard
            className={cx(classes.root, {
                [variant]: variant,
            })}
            {...rest}
        >
            {children}
        </MuiCard>
    );
};

Card.propTypes = {
    variant: PropTypes.oneOf(['default', 'success', 'error', 'warning']),
    children: PropTypes.node.isRequired,
};

Card.defaultProps = {
    variant: 'default',
};

export default Card;
