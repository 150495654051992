import apiClient from '../../api';
import { baseAPIUrl } from '../constants';

export const getIsos = async () => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/isos`,
    });
    return data;
};

export const getRemoteIsos = async () => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/remoteisos`,
    });
    return data;
};

export const pullRemoteIso = async ({ iso, newName }) => {
    const { data } = await apiClient({
        timeout: 0,
        url: `${baseAPIUrl}/remoteisos/${iso}/pull`,
        method: 'POST',
        data: {
            destination: newName,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const copyIso = async ({ iso, newName }) => {
    const { data } = await apiClient({
        timeout: 0,
        url: `${baseAPIUrl}/isos/${iso}/copy`,
        method: 'POST',
        data: {
            destination: newName,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return data;
};

export const deleteIso = async ({ iso }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/isos/${iso}`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};
