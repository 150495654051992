import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { get, isNull, isUndefined } from 'lodash';
import { useNavigate } from 'react-router-dom';

import Loader from '../components/Loader';
import Error from '../components/Error';
import UserContext from './UserContext';
import { getToken, setToken, removeToken } from './persister';
import {
    useUser as useUserQuery,
    useLoginUser as useLoginUserMutation,
    useCreateUser as useCreateUserMutation,
} from './hooks';
import { handleError } from '../utils/handleError';
import { RESPONSE_STATUS_CODES } from '../enums';
import pages from '../pages/pages';

const UserProvider = (props) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const token = getToken();

    const {
        data: user,
        isLoading,
        isError,
        error,
    } = useUserQuery({
        enabled: !isUndefined(token) && !isNull(token),
        onError: (err) => {
            if (
                get(err, 'response.status') ===
                RESPONSE_STATUS_CODES.UNAUTHORIZED
            ) {
                removeToken();
                queryClient.clear();
                navigate(pages.login.path);
            }
        },
        retry: false,
        refetchOnWindowFocus: false,
    });

    const loginUser = useLoginUserMutation({
        onSuccess: async (res) => {
            setToken(res.token);
            await queryClient.invalidateQueries('user');
            navigate(pages.home.path);
        },
    });

    const logoutUser = () => {
        removeToken();
        queryClient.clear();
        navigate(pages.login.path);
    };

    const createUser = useCreateUserMutation({
        onSuccess: async (_, variables) => {
            const { mutate: mutateLoginUser } = loginUser;
            await mutateLoginUser(variables);
            navigate(pages.home.path);
        },
    });

    const value = useMemo(
        () => ({
            user,
            loginUser,
            logoutUser,
            createUser,
        }),
        [user, loginUser, logoutUser, createUser],
    );

    if (isLoading) {
        return <Loader />;
    }

    if (
        isError &&
        get(error, 'response.status', '') !== RESPONSE_STATUS_CODES.UNAUTHORIZED
    ) {
        return <Error message={handleError(error)} />;
    }

    return <UserContext.Provider value={value} {...props} />;
};

export default UserProvider;
