import React from 'react';
import PropTypes from 'prop-types';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { noop } from 'lodash';

import Button from './Button';
import { messages as commonMessages } from '../constants';

const { msgView } = commonMessages;

const ViewButton = ({ onClick, ...rest }) => (
    <Button data-testid="view" onClick={onClick} title={msgView} {...rest}>
        <VisibilityIcon />
    </Button>
);

ViewButton.propTypes = {
    onClick: PropTypes.func,
};

ViewButton.defaultProps = {
    onClick: noop,
};

export default ViewButton;
