import React from 'react';

import { useDiscoverySectionsMapping } from './hooks';
import DiscoveryLayout from '../components/layouts/DiscoveryLayout';
import { prismicOrka } from './prismicOrka';

const OverviewPage = () => {
    const { hero, features } = useDiscoverySectionsMapping(prismicOrka[0].node);

    return (
        <DiscoveryLayout
            heroSectionConfig={hero}
            featuresSectionConfig={features}
        />
    );
};

export default OverviewPage;
