export const messages = {
    msgNodes: 'Nodes',
    msgNodeDetails: 'Node Details',
    msgNodesInfo:
        'Orka nodes provide computational resources for your deployments. To free up resources, delete or purge VMs occupying the node.',
    msgEmptyNodes: `You don't seem to have any nodes available. Contact your Orka administrator or MacStadium support.`,
    msgNodeStateReady:
        'The node is fully operational. You can deploy and manage Orka VMs.',
    msgNodeStateNotReady:
        'The node is not operational. Contact your Orka administrator or MacStadium support.',
    msgNodeStateSandbox:
        'The node is reserved for management with Kubectl. To access its resources, switch to the Orka CLI and Kubectl. You can still manage Orka VMs already deployed on the node, except for cloning to the same node or scaling.',
    msgTabGeneral: 'General',
    msgTabReservedPorts: 'Reserved Ports',
    msgTabReservedPortsInfo:
        'A list of the ports that are already in use on the node. To free a port, remove the respective VM that is using it. Note that Orka reserves certain ports for VNC (5999-6013), Screen Share (5900-5914), SSH (8822-8836), and general operational needs (80, 443, 6443).',
    msgTabEmptyReservedPorts: `No reserved ports found.`,
    msgTabVms: 'VMs',
    msgNodePort: 'Node Port',
    msgVmPort: 'VM Port',
};
