import React from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import CommonProviders from './context/CommonProviders';

const queryClient = new QueryClient();

const AppProviders = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <CommonProviders>{children}</CommonProviders>

        <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
);

AppProviders.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppProviders;
