export const cpuCoresMinNumber = 3;
export const defaultCpuCoresNumber = 6;
export const defaultMemoryNumber = 4;
export const vmNameMaxLength = 38;
const regexVmName = /^[a-z][-a-z0-9]*$/;

export const validators = {
    isValidVmName: (value) => regexVmName.test(value),
    isValidVmNameLength: (value) => value.length <= vmNameMaxLength,
    isValidCoresNumber: (value) => Number(value) > 1,
    isValidMemoryNumber: (value) => Number(value) > 0,
};

export const messages = {
    msgInvalidVmName:
        'The name must consist of lowercase Latin alphanumeric characters (a-z, 0-9) or the dash (-). It must begin with alphabetic and end with an alphanumeric character.',
    msgInvalidVmNameLength: `The name must not exceed ${vmNameMaxLength} characters.`,
    msgInvalidCoresNumber: `Must be greater than 1`,
    msgInvalidMemoryNumber: 'The memory must be a number greater than 0.',
    msgInvalidGpuAndVnc: 'Cannot enable vnc and gpu at the same time',
    msgNetBoost: 'Net Boost',
    msgVms: 'VMs',
    msgVmsInfo:
        'Orka VMs are the deployed instances of Orka VM configs. An Orka VM is a fully fledged macOS VM inside a Docker container.',
    msgEmptyVms: `You don't seem to have any VMs.`,
    msgVmsHint: `VMs take up resources from your nodes.`,
    msgVmConfigs: 'VM Configs',
    msgVmConfigsInfo:
        'Orka VM configurations are the templates for your Orka virtual machines. You can deploy multiple VMs multiple times from a single VM config.',
    msgEmptyVmConfigs: `You don't seem to have any VM configs. Try creating a VM config and deploying a VM from it. `,
    msgVmConfigsHint: `VM configs don't take up resources from your nodes.`,
    msgVmDetails: 'VM Details',
    msgVmConfigDetails: 'VM Config Details',
    msgCreateConfig: 'Create Config',
    msgDeployConfig: 'Deploy Config',
    msgDeploy: 'Deploy',
    msgStart: 'Start',
    msgStop: 'Stop',
    msgSuspend: 'Suspend',
    msgResume: 'Resume',
    msgRevert: 'Revert',
    msgSaveVmImage: 'Save As New Image',
    msgCommitVmImage: 'Commit To Base Image',
    msgPurge: 'Purge',
    msgDelete: 'Delete',
    msgConfigCreated: 'Config successfully created!',
    msgDeployed: 'VM successfully deployed!',
    msgStarted: 'VM successfully started!',
    msgStopped: 'VM successfully stopped!',
    msgSuspended: 'VM successfully suspended!',
    msgResumed: 'VM successfully resumed!',
    msgReverted: 'VM successfully reverted!',
    msgCommitted: 'Successfully committed to the base image!',
    msgSavedVmImage: 'VM successfully saved as a new image!',
    msgPurged: 'VM successfully purged!',
    msgDeleted: 'VM successfully deleted!',
    msgTabGeneral: 'General',
    msgTabNode: 'Node',
    msgTabReservedPorts: 'Reserved Ports',
    msgTabEmptyReservedPorts: 'No reserved ports found!',
    msgNodePort: 'Node Port',
    msgVmPort: 'VM Port',
    msgSaveVmImageInfo:
        'Create a new base image from the selected VM instance.',
    msgSaveVmImageInfo2:
        'This operation stops and starts the VM, and connectivity to the instance will be lost during the process. To use the newly created base image, you need to create a new VM config.',
    msgConfirmCommit: (vmId, baseImage) =>
        `This operation commits the current OS configuration and storage of the selected VM (<b>${vmId}</b>) to its base image (<b>${baseImage}</b>). The base image must not be in use by other VMs. This operation stops and starts the VM, and connectivity to the instances will be lost during the process.<br/><br/>
        This operation overwrites the base image, and you will not be able to restore its previous state.`,
    msgConfirmRevert: (name) =>
        `This will revert the selected VM (<b>${name}</b>) to the latest state of its base image. Any changes made to the VM since its deployment or its last commit to the base image will be lost.<br/><br/>
        This operation cannot be reversed.`,
    msgConfirmPurgeConfig: (name) =>
        `This will terminate the selected VM config (<b>${name}</b>) and remove the respective VM config.<br/><br/> This operation cannot be reversed.`,
    msgConfirmDelete: (name) =>
        `This will terminate the selected VM (<b>${name}</b>) and free the respective resources on your node(s). This operation preserves the respective VM config and you will be able to deploy more VMs from it.<br/><br/>
        This operation cannot be reversed.`,
    msgVnc: 'Enable VNC',
    msgGpu: 'Enable GPU',
    msgVmStatusRunning: 'This VM is operational and ready for use.',
    msgVmStatusStopped: `This VM is powered off. Try starting the VM to make it operational again. If the VM doesn't start, consider reverting or redeploying the VM`,
    msgVmStatusSuspended:
        'This VM is in suspended mode, and the OS is unresponsive. Try resuming the VM to make it operational again.',
    msgVmStatusError:
        'This VM has become inoperable and needs to be removed. Delete or purge, or contact your Orka administrator or MacStadium support to investigate.',
    msgVmStatusPending:
        'This VM is queued for deployment. If not deleted or purged, it will be deployed as soon as sufficient resources are available.',
    msgVmStatusOrphaned:
        'This VM has become orphaned and needs to be removed. Delete or purge, or contact your Orka administrator or MacStadium support to investigate.',
};
