import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '../../../components/mui';
import KeyValueList from '../../../components/KeyValueList';
import { formatDate } from '../../../utils/formatDate';

const itemDefs = [
    {
        key: 'size',
        label: 'Size',
        value: 'size',
    },
    {
        key: 'modified',
        label: 'Last Modified',
        itemContentRenderer: (item) => formatDate(item.modified),
    },
];

const IsosList = ({ items }) => (
    <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4}>
            <KeyValueList items={items} itemDefinitions={itemDefs} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8} />
    </Grid>
);

IsosList.propTypes = {
    items: PropTypes.array,
};

IsosList.defaultProps = {
    items: [],
};

export default IsosList;
