import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';

import { noop } from 'lodash';
import { Box, Grid, MuiButton, Typography } from '../../../components/mui';

import Form from '../../../components/Form';
import { messages as commonMessages } from '../../constants';

const { msgRequired, msgLogin } = commonMessages;

const fields = [
    {
        name: 'token',
        label: 'Token *',
        initialValue: '',
    },
];

const useStyles = makeStyles(
    ({ palette, typography, breakpoints, spacing }) => ({
        footer: {
            backgroundColor: palette.background.default,
            paddingLeft: spacing(6),
            paddingRight: spacing(6),
            paddingTop: spacing(3),
            paddingBottom: spacing(3),
            marginTop: spacing(2),
            borderRadius: typography.pxToRem(8),

            [breakpoints.down('sm')]: {
                backgroundColor: 'initial',
                padding: 0,
            },
        },
        footerContent: {
            [breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                textAlign: 'left',
                alignItems: 'flex-start',
            },
        },
        title: {
            fontWeight: 500,
            fontSize: typography.pxToRem(28),
        },
        hint: {
            [breakpoints.down('xs')]: {
                marginTop: spacing(2),
            },
        },
        submit: {
            [breakpoints.up('md')]: {
                textAlign: 'right',
            },
        },
    }),
);

const LoginForm = ({ children, isSubmitting, onSubmit }) => {
    const classes = useStyles();

    const handleValidate = (values) => {
        const errors = {};

        if (!values.token) {
            errors.token = msgRequired;
        }

        return errors;
    };

    const handleSubmit = (values) => {
        onSubmit(values);
    };

    const formContentRenderer = () => (
        <Box className={classes.footer}>
            <Grid
                container
                alignItems="center"
                className={classes.footerContent}
            >
                <Grid item xs={12} md={9} />
                <Grid item xs={12} md={3} className={classes.submit}>
                    <MuiButton
                        data-testid="submit"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        <Box display="right" alignItems="center">
                            {isSubmitting && (
                                <Box mr={1}>
                                    <CircularProgress size="0.85rem" />
                                </Box>
                            )}

                            {msgLogin}
                        </Box>
                    </MuiButton>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
            formLeftTitleContentTemplate={
                <Box mt={1}>
                    <Typography className={classes.title} variant="h5">
                        {msgLogin}
                    </Typography>
                </Box>
            }
        >
            {children}
        </Form>
    );
};

LoginForm.propTypes = {
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
};

LoginForm.defaultProps = {
    isSubmitting: false,
    onSubmit: noop,
    children: undefined,
};

export default LoginForm;
