import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Box } from './mui';

const Loader = ({ size }) => (
    <Box mt={3} data-testid="loading">
        <CircularProgress size={size} />
    </Box>
);

Loader.propTypes = {
    size: PropTypes.string,
};

Loader.defaultProps = {
    size: '1.5rem',
};

export default Loader;
