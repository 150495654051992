/* eslint react/no-array-index-key: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
    MuiCard,
    CardContent,
    Typography,
    Tooltip,
    Grid,
} from '../../../components/mui';
import Hint from '../../components/Hint';
import { formatAny } from '../../../utils/formatAny';
import { messages } from '../constants';

const { msgNodePort, msgVmPort, msgTabEmptyReservedPorts } = messages;

const PortsList = ({ items }) => (
    <Grid container spacing={3}>
        {isEmpty(items) && (
            <Hint variant="warning">{msgTabEmptyReservedPorts}</Hint>
        )}

        {!isEmpty(items) &&
            items.map((item, index) => (
                <Grid key={index} item xs={12} sm={12} md={6} lg={3}>
                    <MuiCard>
                        <CardContent>
                            <Typography
                                gutterBottom
                                color="textSecondary"
                                display="block"
                                variant="caption"
                            >
                                {formatAny(item.protocol)}
                            </Typography>
                            <Tooltip title={msgVmPort}>
                                <Typography variant="h5" display="inline">
                                    {formatAny(item.guestPort)}
                                </Typography>
                            </Tooltip>
                            <Tooltip title={msgNodePort}>
                                <Typography
                                    component="p"
                                    color="textSecondary"
                                    variant="body1"
                                    display="inline"
                                >
                                    {` / ${formatAny(item.hostPort)}`}
                                    <Typography
                                        color="textSecondary"
                                        variant="caption"
                                        display="inline"
                                    >
                                        <sup> Node</sup>
                                    </Typography>
                                </Typography>
                            </Tooltip>
                        </CardContent>
                    </MuiCard>
                </Grid>
            ))}
    </Grid>
);

PortsList.propTypes = {
    items: PropTypes.array,
};

PortsList.defaultProps = {
    items: [],
};

export default PortsList;
