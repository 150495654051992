import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { noop } from 'lodash';

import { MuiButton, MuiDialog, DialogActions, DialogContent } from './mui';
import DialogTitle from './DialogTitle';
import {
    confirmOptionsPropTypes,
    confirmOptionsDefaultProps,
} from './propTypes';

const ConfirmDialog = ({ isOpen, options, onCancel, onConfirm }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        title,
        description,
        confirmationText,
        cancellationText,
        dialogProps,
        confirmationButtonProps,
        cancellationButtonProps,
    } = options;

    return (
        <MuiDialog
            fullScreen={fullScreen}
            fullWidth
            {...dialogProps}
            open={isOpen}
            onClose={onCancel}
        >
            {title && <DialogTitle onClose={onCancel}>{title}</DialogTitle>}
            {description && <DialogContent>{description}</DialogContent>}

            <DialogActions>
                <MuiButton
                    data-testid="cancel-confirm"
                    {...cancellationButtonProps}
                    onClick={onCancel}
                >
                    {cancellationText}
                </MuiButton>
                <MuiButton
                    data-testid="confirm"
                    color="primary"
                    {...confirmationButtonProps}
                    onClick={onConfirm}
                >
                    {confirmationText}
                </MuiButton>
            </DialogActions>
        </MuiDialog>
    );
};

ConfirmDialog.propTypes = {
    isOpen: PropTypes.bool,
    options: confirmOptionsPropTypes,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
};

ConfirmDialog.defaultProps = {
    isOpen: false,
    options: {
        ...confirmOptionsDefaultProps,
        confirmationButtonProps: {
            color: 'inherit',
        },
    },
    onCancel: noop,
    onConfirm: noop,
};

export default ConfirmDialog;
