import apiClient from '../../api';
import { baseAPIUrl } from '../constants';

export const getImages = async () => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/images`,
    });
    return data;
};

export const getRemoteImages = async () => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/remoteimages`,
    });
    return data;
};

export const generateImage = async ({ fileName, fileSize }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/images`,
        method: 'POST',
        data: {
            name: fileName,
            size: fileSize,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const pullRemoteImage = async ({ image, newName }) => {
    const { data } = await apiClient({
        timeout: 0,
        url: `${baseAPIUrl}/remoteimages/${image}/pull`,
        method: 'POST',
        data: {
            destination: newName,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return data;
};

export const copyImage = async ({ image, newName }) => {
    const { data } = await apiClient({
        timeout: 0,
        url: `${baseAPIUrl}/images/${image}/copy`,
        method: 'POST',
        data: {
            destination: newName,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const deleteImage = async ({ image }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/images/${image}`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};
