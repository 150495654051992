import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../../components/mui';

// Remove this after Experimental Tabs API is stable
// https://material-ui.com/components/tabs/#experimental-tabs-api
const TabPanel = ({ children, value, index, ...rest }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...rest}
    >
        {value === index && <Box p={3}>{children}</Box>}
    </div>
);

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

TabPanel.defaultProps = {
    children: null,
};

export default TabPanel;
