import { useQuery, useMutation } from 'react-query';

import {
    getIsos,
    getRemoteIsos,
    pullRemoteIso,
    copyIso,
    deleteIso,
} from './queries';

export const useIsos = (config) => useQuery('isos', getIsos, config);

export const useRemoteIsos = (config) =>
    useQuery('isos-remote', getRemoteIsos, config);

export const usePullRemoteIso = (config) => useMutation(pullRemoteIso, config);

export const useCopyIso = (config) => useMutation(copyIso, config);

export const useDeleteIso = (config) => useMutation(deleteIso, config);
