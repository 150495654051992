import config from '../config';

const pathWithBase = (path) => `${config.BASE_PATH.trimEnd('/')}/${path}`;

const pages = {
    home: {
        path: pathWithBase(''),
    },
    login: {
        path: pathWithBase('login'),
    },
    register: {
        path: pathWithBase('register'),
    },
    overview: {
        path: pathWithBase('overview'),
    },
    nodes: {
        path: pathWithBase('nodes'),
    },
    nodeDetails: {
        path: ':nodeId',
    },
    vmConfigs: {
        path: pathWithBase('vm-configs'),
    },
    vmConfigDetails: {
        path: ':vmId',
    },
    vms: {
        path: pathWithBase('vms'),
    },
    vmDetails: {
        path: ':vmId',
    },
    images: {
        path: pathWithBase('images'),
    },
    imageDetails: {
        path: ':imageId',
    },
    isos: {
        path: pathWithBase('isos'),
    },
    isoDetails: {
        path: ':isoId',
    },
    profile: {
        path: pathWithBase('profile'),
    },
};

export default pages;
