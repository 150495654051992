import React, { useMemo, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { isEmpty, get } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { MuiHyperlink } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Hint from '../components/Hint';
import Loader from '../../components/Loader';
import Toolbar from '../../components/Toolbar';
import RefreshButton from '../components/RefreshButton';
import ViewButton from '../components/ViewButton';
import NodesTable from './components/NodesTable';
import { useNodes } from './hooks';
import { useFeatures } from '../hooks';
import { messages } from './constants';
import { handleError } from '../../utils/handleError';
import { getNodes, getUpdatedSelectedNode } from './selectors';

const { msgNodes, msgNodesInfo, msgEmptyNodes } = messages;

const portalUrl = 'https://portal.macstadium.com/';

const Nodes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null);
    const [gpuEnabled, setGPUEnabled] = useState(false);
    const queryClient = useQueryClient();

    const updateSelection = (res) => {
        const nodes = getNodes(res);

        setSelectedItem(
            getUpdatedSelectedNode(nodes, get(selectedItem, 'name', '')),
        );
    };

    // queries
    const { data: features } = useFeatures();
    const { data, isLoading, isFetching } = useNodes({
        onError: (err) => toast.error(handleError(err)),
        onSuccess: (res) => {
            updateSelection(res);
        },
    });

    const nodes = useMemo(() => getNodes(data), [data]);

    const selectedNodeId = get(selectedItem, 'name', '');

    useEffect(() => {
        if (!get(location, 'state.selectedId')) {
            return;
        }

        setSelectedItem(
            getUpdatedSelectedNode(nodes, location.state.selectedId),
        );
    }, [location, nodes, selectedItem]);

    useEffect(() => {
        if (features && features.gpuPassthroughEnabled) {
            setGPUEnabled(true);
        }
    }, [features]);

    const handleDetails = () => {
        navigate(`${selectedNodeId}`);
    };

    const handleRefresh = () => {
        queryClient.invalidateQueries('nodes');
    };

    const handleSelect = (item) => {
        setSelectedItem(item);
    };

    return (
        <>
            <PageTitle primaryText={msgNodes} secondaryText={msgNodesInfo} />

            <Toolbar
                rightContent={
                    <>
                        <ViewButton
                            onClick={handleDetails}
                            disabled={!selectedItem}
                        />
                        <RefreshButton onClick={handleRefresh} />
                    </>
                }
            />

            {!isLoading && isEmpty(nodes) && (
                <Hint variant="warning">{msgEmptyNodes}</Hint>
            )}

            {!isLoading && !isEmpty(nodes) && (
                <>
                    <NodesTable
                        items={nodes}
                        selectedItem={selectedItem}
                        onSelect={handleSelect}
                        gpuEnabled={gpuEnabled}
                    />

                    <Hint>
                        <span>
                            To add more resources, contact {` `}
                            <MuiHyperlink
                                color="inherit"
                                href={portalUrl}
                                target="_blank"
                            >
                                <strong>MacStadium support</strong>
                            </MuiHyperlink>
                            .
                        </span>
                    </Hint>
                </>
            )}

            {(isFetching || isLoading) && <Loader />}
        </>
    );
};

export default Nodes;
