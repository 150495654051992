import React, { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
    PowerSettingsNew as PowerSettingsNewIcon,
    Stop as StopIcon,
    Pause as PauseIcon,
    PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import { isEmpty, size, get, filter } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
    DialogContentText,
    MuiHyperlink,
    ButtonGroup,
} from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Hint from '../components/Hint';
import Loader from '../../components/Loader';
import Toolbar from '../../components/Toolbar';
import RefreshButton from '../components/RefreshButton';
import Button from '../components/Button';
import ViewButton from '../components/ViewButton';
import MoreButton from '../components/MoreButton';
import VmsTable from './components/VmsTable';
import SaveVmImageDialog from './components/SaveVmImageDialog';
import { messages } from './constants';
import { handleError } from '../../utils/handleError';
import { handleSuccess } from '../../utils/handleSuccess';
import {
    useVms,
    useDeleteVm,
    useRevertVm,
    useStartVm,
    useStopVm,
    useSuspendVm,
    useResumeVm,
    useCommitVmImage,
} from './hooks';
import useConfirm from '../../context/useConfirm';
import { VM_STATUSES } from './enums';
import { getVms, getUpdatedSelectedVm } from './selectors';
import { formatHtml } from '../../utils/formatHtml';
import pages from '../pages';

const {
    msgConfirmCommit,
    msgConfirmRevert,
    msgConfirmDelete,
    msgVms,
    msgVmsInfo,
    msgEmptyVms,
    msgVmsHint,
    msgStart,
    msgStarted,
    msgStop,
    msgStopped,
    msgSuspend,
    msgSuspended,
    msgResume,
    msgResumed,
    msgRevert,
    msgSaveVmImage,
    msgCommitVmImage,
    msgReverted,
    msgDelete,
    msgDeleted,
    msgCommitted,
} = messages;

const renderVmActions = ({
    selectedItem,
    onStart,
    onStop,
    onSuspend,
    onResume,
}) => {
    const status = get(selectedItem, 'status');

    let template;

    switch (status) {
        case VM_STATUSES.STOPPED:
            template = (
                <ButtonGroup>
                    <Button
                        data-testid="start"
                        onClick={onStart}
                        title={`${msgStart} VM`}
                        disabled={!selectedItem}
                        startIcon={<PowerSettingsNewIcon />}
                    >
                        {msgStart}
                    </Button>
                </ButtonGroup>
            );
            break;
        case VM_STATUSES.RUNNING:
            template = (
                <ButtonGroup>
                    <Button
                        data-testid="stop"
                        onClick={onStop}
                        title={`${msgStop} VM`}
                        disabled={!selectedItem}
                        startIcon={<StopIcon />}
                    >
                        {msgStop}
                    </Button>

                    <Button
                        data-testid="suspend"
                        onClick={onSuspend}
                        title={`${msgSuspend} VM`}
                        disabled={!selectedItem}
                        startIcon={<PauseIcon />}
                    >
                        {msgSuspend}
                    </Button>
                </ButtonGroup>
            );
            break;
        case VM_STATUSES.SUSPENDED:
            template = (
                <ButtonGroup>
                    <Button
                        data-testid="stop"
                        onClick={onStop}
                        title={`${msgStop} VM`}
                        disabled={!selectedItem}
                        startIcon={<StopIcon />}
                    >
                        {msgStop}
                    </Button>

                    <Button
                        data-testid="resume"
                        onClick={onResume}
                        title={`${msgResume} VM`}
                        disabled={!selectedItem}
                        startIcon={<PlayArrowIcon />}
                    >
                        {msgResume}
                    </Button>
                </ButtonGroup>
            );
            break;
        default:
            template = null;
    }

    return template;
};

const Vms = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const confirm = useConfirm();
    const [selectedItem, setSelectedItem] = useState(null);
    const [showSaveVmImageModal, setShowSaveVmImageModal] = useState(false);
    const queryClient = useQueryClient();

    const resetSelection = () => setSelectedItem(null);

    const updateSelection = (res) => {
        const vms = getVms(res);

        setSelectedItem(
            getUpdatedSelectedVm(vms, get(selectedItem, 'name', '')),
        );
    };

    // queries
    const { data, isLoading, isFetching } = useVms({
        onError: (err) => toast.error(handleError(err)),
        onSuccess: (res) => {
            updateSelection(res);
        },
    });

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('vms');
        },
    };
    const { mutate: mutateStartVm } = useStartVm({
        ...mutateProps,
        onSuccess: (res) => toast.success(handleSuccess(res, msgStarted)),
    });
    const { mutate: mutateStopVm } = useStopVm({
        ...mutateProps,
        onSuccess: (res) => toast.success(handleSuccess(res, msgStopped)),
    });
    const { mutate: mutateSuspendVm } = useSuspendVm({
        ...mutateProps,
        onSuccess: (res) => toast.success(handleSuccess(res, msgSuspended)),
    });
    const { mutate: mutateResumeVm } = useResumeVm({
        ...mutateProps,
        onSuccess: (res) => toast.success(handleSuccess(res, msgResumed)),
    });
    const { mutate: mutateRevertVm } = useRevertVm({
        ...mutateProps,
        onSuccess: (res) => toast.success(handleSuccess(res, msgReverted)),
    });
    const { mutate: mutateDeleteVm } = useDeleteVm({
        ...mutateProps,
        onSuccess: (res) => {
            resetSelection();
            toast.success(handleSuccess(res, msgDeleted));
        },
    });
    const { mutate: mutateCommitVmImage } = useCommitVmImage({
        ...mutateProps,
        onSuccess: (res) => {
            resetSelection();
            toast.success(handleSuccess(res, msgCommitted));
        },
    });

    const vms = useMemo(() => getVms(data), [data]);

    const selectedVmId = get(selectedItem, 'name', '');

    useEffect(() => {
        if (!get(location, 'state.selectedId') || selectedItem) {
            return;
        }

        setSelectedItem(getUpdatedSelectedVm(vms, location.state.selectedId));
    }, [location, vms, selectedItem]);

    const handleDetails = () => {
        navigate(`${selectedVmId}`);
    };

    const handleRefresh = () => {
        queryClient.invalidateQueries('vms');
    };

    const handleSelect = (item) => {
        setSelectedItem(item);
    };

    // actions
    const handleStartVm = async () => {
        await mutateStartVm({
            name: selectedVmId,
        });
    };

    const handleStopVm = async () => {
        await mutateStopVm({
            name: selectedVmId,
        });
    };

    const handleSuspendVm = async () => {
        await mutateSuspendVm({
            name: selectedVmId,
        });
    };

    const handleResumeVm = async () => {
        await mutateResumeVm({
            name: selectedVmId,
        });
    };

    const handleRevertVm = async () => {
        if (
            await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmRevert(selectedVmId),
                        )}
                    />
                ),
            })
        ) {
            await mutateRevertVm({
                name: selectedVmId,
            });
        }
    };

    const handleDeleteVm = async () => {
        if (
            await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmDelete(selectedVmId),
                        )}
                    />
                ),
            })
        ) {
            await mutateDeleteVm({
                name: selectedVmId,
            });
        }
    };

    const handleCommitVmToBaseImage = async () => {
        if (
            await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmCommit(
                                selectedVmId,
                                get(selectedItem, 'image'),
                            ),
                        )}
                    />
                ),
            })
        ) {
            await mutateCommitVmImage({
                name: selectedVmId,
            });
        }
    };

    const handleToggleSaveVmImage = () => {
        setShowSaveVmImageModal(!showSaveVmImageModal);
    };

    const isBaseImageUsed = () => {
        const selectedVmBaseImage = get(selectedItem, 'image');

        const vmsUsingBaseImage = filter(
            vms,
            (vm) => get(vm, 'image') === selectedVmBaseImage,
        );

        return size(vmsUsingBaseImage) > 1;
    };

    const commitImageTooltipMsg = () => {
        if (isBaseImageUsed()) {
            return 'This operation is not available. The base image is in use by multiple VMs.';
        }
        return '';
    };

    return (
        <>
            <PageTitle primaryText={msgVms} secondaryText={msgVmsInfo} />

            <Toolbar
                leftContent={
                    <>
                        <MoreButton
                            disabled={!selectedItem}
                            menuItems={[
                                {
                                    onClick: handleCommitVmToBaseImage,
                                    dataTestId: 'commit-image',
                                    title: msgCommitVmImage,
                                    tooltip: commitImageTooltipMsg(),
                                    disabled: isBaseImageUsed(),
                                },
                                {
                                    onClick: handleToggleSaveVmImage,
                                    dataTestId: 'save-image',
                                    title: msgSaveVmImage,
                                    tooltip: '',
                                },
                                {
                                    onClick: handleRevertVm,
                                    dataTestId: 'revert',
                                    title: msgRevert,
                                },
                                {
                                    onClick: handleDeleteVm,
                                    dataTestId: 'delete',
                                    color: 'error',
                                    title: msgDelete,
                                },
                            ]}
                        />

                        {renderVmActions({
                            selectedItem,
                            onStart: handleStartVm,
                            onStop: handleStopVm,
                            onSuspend: handleSuspendVm,
                            onResume: handleResumeVm,
                        })}
                    </>
                }
                rightContent={
                    <>
                        <ViewButton
                            onClick={handleDetails}
                            disabled={!selectedItem}
                        />
                        <RefreshButton onClick={handleRefresh} />
                    </>
                }
            />

            {!isLoading && isEmpty(vms) && (
                <Hint variant="warning">
                    {msgEmptyVms}
                    <span>
                        {' '}
                        Perhaps you have some{' '}
                        <MuiHyperlink
                            component={Link}
                            color="inherit"
                            to={pages.vmConfigs.path}
                        >
                            <strong>VM configs</strong>
                        </MuiHyperlink>{' '}
                        that you can use to deploy VMs?
                    </span>
                </Hint>
            )}

            {!isLoading && !isEmpty(vms) && (
                <>
                    <VmsTable
                        items={vms}
                        selectedItem={selectedItem}
                        onSelect={handleSelect}
                    />

                    <Hint>{msgVmsHint}</Hint>
                </>
            )}

            {(isFetching || isLoading) && <Loader />}

            <SaveVmImageDialog
                selectedItem={selectedItem}
                isOpen={showSaveVmImageModal}
                onClose={handleToggleSaveVmImage}
            />
        </>
    );
};

export default Vms;
