import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get, noop } from 'lodash';

import Dialog from '../../../components/Dialog';
import CreateConfigForm from './CreateConfigForm';
import { handleError } from '../../../utils/handleError';
import { handleSuccess } from '../../../utils/handleSuccess';
import { messages } from '../constants';
import { useCreateConfig } from '../hooks';
import { useImages } from '../../images/hooks';

const { msgCreateConfig, msgConfigCreated } = messages;
const showError = (err) => toast.error(handleError(err));

const CreateConfigDialog = ({ gpuEnabled, isOpen, onClose }) => {
    const queryClient = useQueryClient();

    // queries
    const { data } = useImages({
        onError: showError,
    });

    // mutations
    const mutateProps = {
        onError: showError,
        onSettled: () => {
            queryClient.invalidateQueries('vm-configs');
        },
    };
    const { mutate: mutateCreateVm, isLoading } = useCreateConfig({
        ...mutateProps,
        onSuccess: (res) => {
            onClose();
            toast.success(handleSuccess(res, msgConfigCreated));
        },
    });

    // actions
    const handleCreateVm = async ({
        name,
        image,
        cpu,
        vncConsole,
        gpuPassthrough,
        netBoost,
        memory,
    }) => {
        await mutateCreateVm({
            name,
            image: image.name,
            cpu,
            vncConsole,
            gpuPassthrough,
            netBoost,
            memory,
        });
    };

    const handleClose = () => {
        onClose();
    };

    const images = get(data, 'items', []);

    return (
        <Dialog
            isSubmitting={isLoading}
            isOpen={isOpen}
            title={msgCreateConfig}
            onClose={handleClose}
        >
            <CreateConfigForm
                gpuEnabled={gpuEnabled}
                images={images}
                onClose={handleClose}
                onSubmit={handleCreateVm}
            />
        </Dialog>
    );
};

CreateConfigDialog.propTypes = {
    gpuEnabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

CreateConfigDialog.defaultProps = {
    gpuEnabled: false,
    isOpen: false,
    onClose: noop,
};

export default CreateConfigDialog;
