import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Box, Typography } from '../../components/mui';
import Badge from '../../components/Badge';
import Indicator from '../../components/Indicator';

const Status = ({ title, label, variant }) => (
    <Tooltip title={title}>
        <Typography component="span" variant="body2">
            <Badge
                variant={variant}
                icon={
                    <Box pl={1}>
                        <Indicator disableAnimation variant={variant} />
                    </Box>
                }
                label={label}
            />
        </Typography>
    </Tooltip>
);

Status.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'success', 'error', 'warning']),
};

Status.defaultProps = {
    title: '',
    label: '',
    variant: 'default',
};

export default Status;
