import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, lowerCase } from 'lodash';
import { Link } from 'react-router-dom';

import { MuiHyperlink } from '../../../components/mui';
import Table from '../../../components/Table';
import Status from '../../components/Status';
import ConnectionButton from './ConnectionButton';
import { VM_STATUSES } from '../enums';
import { formatAny } from '../../../utils/formatAny';
import { messages } from '../constants';

const {
    msgVmStatusRunning,
    msgVmStatusStopped,
    msgVmStatusSuspended,
    msgVmStatusError,
    msgVmStatusPending,
    msgVmStatusOrphaned,
} = messages;

const MAP_VM_STATUS_TYPES = {
    [VM_STATUSES.RUNNING]: 'success',
    [VM_STATUSES.STOPPED]: 'warning',
    [VM_STATUSES.PENDING]: 'warning',
    [VM_STATUSES.ORPHANED]: 'error',
    [VM_STATUSES.ERROR]: 'error',
};

const MAP_VM_STATUS_TITLES = {
    [VM_STATUSES.RUNNING]: msgVmStatusRunning,
    [VM_STATUSES.STOPPED]: msgVmStatusStopped,
    [VM_STATUSES.SUSPENDED]: msgVmStatusSuspended,
    [VM_STATUSES.ERROR]: msgVmStatusError,
    [VM_STATUSES.PENDING]: msgVmStatusPending,
    [VM_STATUSES.ORPHANED]: msgVmStatusOrphaned,
};

export const getTitle = (status) =>
    MAP_VM_STATUS_TITLES[lowerCase(status)] || '';
export const getVariant = (status) =>
    MAP_VM_STATUS_TYPES[lowerCase(status)] || 'default';
export const getLabel = (status) => formatAny(lowerCase(status));

const itemDefs = [
    {
        key: 'name',
        label: 'Name',
        itemContentRenderer: (item) => (
            <MuiHyperlink to={get(item, 'name', '')} component={Link}>
                {formatAny(item.name)}
            </MuiHyperlink>
        ),
    },
    {
        key: 'owner',
        label: 'Owner',
        value: 'owner',
    },
    {
        key: 'image',
        label: 'Base Image',
        value: 'image',
    },
    {
        key: 'node',
        label: 'Node',
        value: 'node',
    },
    {
        key: 'gpu',
        label: 'GPU',
        itemContentRenderer: (item) => {
            let state = 'disabled';
            let status = 'warning';
            let title = 'GPU is either disabled or N/A on this VM';
            if (item.gpu && item.gpu !== 'N/A') {
                state = 'enabled';
                status = 'success';
                title = 'GPU is successfully enabled on this VM';
            }

            return <Status title={title} label={state} variant={status} />;
        },
    },
    {
        key: 'status',
        label: 'Status',
        itemContentRenderer: (item) => {
            const status = get(item, 'status', '');

            return (
                <Status
                    title={getTitle(status)}
                    label={getLabel(status)}
                    variant={getVariant(status)}
                />
            );
        },
    },
    {
        key: 'connection',
        label: 'Connection',
        itemContentRenderer: (item) => <ConnectionButton item={item} />,
    },
];

const VmsTable = ({ items, selectedItem, onSelect }) => (
    <Table
        primaryKey="name"
        items={items}
        itemDefinitions={itemDefs}
        selectedItem={selectedItem}
        onSelect={onSelect}
    />
);

VmsTable.propTypes = {
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    onSelect: PropTypes.func,
};

VmsTable.defaultProps = {
    items: [],
    selectedItem: null,
    onSelect: noop,
};

export default VmsTable;
