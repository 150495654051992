import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ConfirmContext from './ConfirmContext';
import ConfirmDialog from '../components/ConfirmDialog';

import { confirmOptionsDefaultProps as defaultProps } from '../components/propTypes';

const ConfirmProvider = ({ children, defaultOptions }) => {
    const [options, setOptions] = useState({
        ...defaultProps,
        ...defaultOptions,
    });
    const [resolveItem, setResolveItem] = useState([]);
    const [resolve] = resolveItem;

    const confirm = useCallback(
        (o = {}) =>
            new Promise((res) => {
                setOptions({ ...defaultProps, ...defaultOptions, ...o });
                setResolveItem([res]);
            }),
        [defaultOptions],
    );

    const handleCancel = useCallback(() => {
        resolve(false);
        setResolveItem([]);
    }, [resolve]);

    const handleConfirm = useCallback(() => {
        resolve(true);
        setResolveItem([]);
    }, [resolve]);

    return (
        <>
            <ConfirmContext.Provider value={confirm}>
                {children}
            </ConfirmContext.Provider>

            <ConfirmDialog
                isOpen={resolveItem.length === 1}
                options={options}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        </>
    );
};

ConfirmProvider.propTypes = {
    children: PropTypes.node.isRequired,
    defaultOptions: PropTypes.object,
};

ConfirmProvider.defaultProps = {
    defaultOptions: {},
};

export default ConfirmProvider;
