import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
        flexGrow: 1,
        padding: spacing(2),
        paddingTop: spacing(10),
        paddingBottom: spacing(14),
        [breakpoints.up('sm')]: {
            padding: spacing(3),
            paddingTop: spacing(10),
            paddingBottom: spacing(10),
        },
    },
}));

const Main = ({ children }) => {
    const classes = useStyles();

    return <main className={classes.root}>{children}</main>;
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Main;
