import PropTypes from 'prop-types';

export const itemDefinitionPropTypes = PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    itemContentRenderer: PropTypes.func,
});

export const itemDefinitionsPropTypes = PropTypes.arrayOf(
    itemDefinitionPropTypes,
);

export const itemsDefaultProps = [
    {
        name: 'name 1',
        value: 'value 1',
    },
    {
        name: 'name 2',
        value: 'value 2',
    },
    {
        name: 'name 3',
        value: 'value 3',
    },
];

export const itemDefinitionsDefaultProps = [
    {
        key: 'key1',
        label: 'label 1',
        value: 'name',
    },
    {
        key: 'key2',
        label: 'label 2',
        value: 'value',
    },
];

export const confirmOptionsPropTypes = PropTypes.shape({
    title: PropTypes.node,
    description: PropTypes.node,
    confirmationText: PropTypes.string,
    cancellationText: PropTypes.string,
    dialogProps: PropTypes.object,
    confirmationButtonProps: PropTypes.object,
    cancellationButtonProps: PropTypes.object,
});

export const confirmOptionsDefaultProps = {
    title: 'Are you sure you want to continue?',
    description: '',
    confirmationText: 'Yes',
    cancellationText: 'No',
    dialogProps: {},
    confirmationButtonProps: {},
    cancellationButtonProps: {},
};
