import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Tooltip } from '../../../components/mui';
import KeyValueList from '../../../components/KeyValueList';
import { formatIpv4 } from '../../../utils/ip';
import { formatAny } from '../../../utils/formatAny';
import { addNodeGPURows } from '../../../utils/addGPURows';

import {
    getTitle as getStateTitle,
    getLabel as getStateLabel,
} from './NodesTable';

const itemDefs = [
    {
        key: 'name',
        label: 'Name',
        value: 'name',
    },
    {
        key: 'address',
        label: 'Address',
        itemContentRenderer: (item) => formatIpv4(formatAny(item.nodeIP)),
    },
    {
        key: 'availableCpu',
        label: 'Available Cores',
        value: 'availableCpu',
    },
    {
        key: 'allocatableCpu',
        label: 'Allocatable Cores',
        value: 'allocatableCpu',
    },
    {
        key: 'availableMemory',
        label: 'Available Memory',
        value: 'availableMemory',
    },
    {
        key: 'allocatableMemory',
        label: 'Allocatable Memory',
        value: 'allocatableMemory',
    },
    {
        key: 'nodeType',
        label: 'Type',
        value: 'nodeType',
    },
    {
        key: 'state',
        label: 'State',
        itemContentRenderer: (item) => (
            <Tooltip title={getStateTitle(item.state)}>
                <Typography component="span" variant="body2">
                    {getStateLabel(item.state)}
                </Typography>
            </Tooltip>
        ),
    },
];

const NodesList = ({ items, gpuEnabled }) => {
    const itemDefinitions = addNodeGPURows(gpuEnabled, itemDefs);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <KeyValueList items={items} itemDefinitions={itemDefinitions} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8} />
        </Grid>
    );
};

NodesList.propTypes = {
    items: PropTypes.array,
    gpuEnabled: PropTypes.bool,
};

NodesList.defaultProps = {
    items: [],
    gpuEnabled: false,
};

export default NodesList;
