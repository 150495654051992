import { get } from 'lodash';
import { isAppleSiliconImageType } from './imageType';

export const extractVmPorts = (vm) => {
    const result = [
        {
            guestPort: isAppleSiliconImageType(vm.type) ? 22 : 8821,
            hostPort: vm.ssh,
            protocol: 'TCP',
            node: vm.node,
        },
        {
            guestPort: 5900,
            hostPort: vm.screenshare,
            protocol: 'TCP',
            node: vm.node,
        },
    ];

    if (get(vm, 'vnc', undefined)) {
        result.push({
            guestPort: isAppleSiliconImageType(vm.type) ? vm.vnc : 5999,
            hostPort: vm.vnc,
            protocol: 'TCP',
            node: vm.node,
        });
    }

    if (get(vm, 'reservedPorts', '')) {
        const reservedPorts = vm.reservedPorts.split(',').map((p) => ({
            hostPort: p.split(':')[0],
            guestPort: p.split(':')[1],
            protocol: 'TCP',
            node: vm.node,
        }));
        result.push(...reservedPorts);
    }

    return result;
};
