import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ExitToApp as ExitToAppIcon,
    AccountCircle as AccountCircleIcon,
} from '@material-ui/icons';

import {
    Menu,
    MenuItem,
    IconButton,
    Divider,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
} from '../../components/mui';
import { messages as commonMessages } from '../constants';
import useUser from '../../context/useUser';

const { msgLogout } = commonMessages;

export const defaultMenuProps = {
    keepMounted: true,
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    PaperProps: {
        style: {
            width: '12.5rem',
        },
    },
};

const useStyles = makeStyles(({ spacing }) => ({
    iconButton: {
        marginRight: spacing(-2),
        color: 'inherit',
    },
}));

const UserMenu = () => {
    const classes = useStyles();
    const { logoutUser } = useUser();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = () => {
        logoutUser();
    };

    const menuOpen = Boolean(anchorEl);

    return (
        <>
            <Tooltip title="Settings">
                <IconButton
                    data-testid="avatar"
                    aria-controls="user-menu"
                    aria-haspopup="true"
                    aria-label="Open User Menu"
                    onClick={handleClick}
                    className={classes.iconButton}
                >
                    <AccountCircleIcon />
                </IconButton>
            </Tooltip>

            <Menu
                {...defaultMenuProps}
                id="user-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
            >
                <Divider />
                <MenuItem onClick={handleLogoutClick} data-testid="logout">
                    <ListItemText primary={msgLogout} />

                    <ListItemSecondaryAction>
                        <ExitToAppIcon />
                    </ListItemSecondaryAction>
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
