import { get } from 'lodash';

import { messages as commonMessages } from '../pages/constants';

const { msgNetworkError } = commonMessages;

export const handleError = (error, message) => {
    if (message) {
        return message;
    }

    let msg;

    if (get(error, 'response')) {
        msg =
            get(error, 'response.data.errors[0].message') ||
            get(error, 'response.data.message');
    } else if (get(error, 'message')) {
        msg = get(error, 'message');
    }

    return msg || msgNetworkError;
};
