import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Tabs, Tab, Paper } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Toolbar from '../../components/Toolbar';
import Loader from '../../components/Loader';
import RefreshButton from '../components/RefreshButton';
import BackButton from '../components/BackButton';
import ImagesList from './components/ImagesList';
import TabPanel from '../components/TabPanel';
import { messages } from './constants';
import { useImages } from './hooks';
import { handleError } from '../../utils/handleError';
import { getSelectedImages } from './selectors';

const { msgImageDetails, msgTabGeneral } = messages;

const ImageDetails = () => {
    const { imageId } = useParams();
    const queryClient = useQueryClient();

    // queries
    const { data, isFetching } = useImages({
        onError: (err) => toast.error(handleError(err)),
    });

    const selectedImages = getSelectedImages(data, [imageId]);

    const handleRefresh = () => {
        queryClient.invalidateQueries('images');
    };

    return (
        <>
            <BackButton />

            <PageTitle
                primaryText={msgImageDetails}
                secondaryText={imageId}
                noBottomMargin
            />

            <Toolbar rightContent={<RefreshButton onClick={handleRefresh} />} />

            <Paper elevation={1}>
                <Tabs value={0}>
                    <Tab label={msgTabGeneral} />
                </Tabs>

                <TabPanel value={0} index={0}>
                    <ImagesList items={selectedImages} />
                </TabPanel>
            </Paper>

            {isFetching && <Loader />}
        </>
    );
};

export default ImageDetails;
