import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

import Button from './Button';
import { messages as commonMessages } from '../constants';

const { msgBack } = commonMessages;

const BackButton = ({ routerTo, routerOptions }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (routerTo) {
            navigate(routerTo, routerOptions);
        } else {
            navigate(-1);
        }
    };

    return (
        <Button
            className="pl-0"
            data-testid="back"
            onClick={handleClick}
            title={msgBack}
            size="small"
            variant="text"
            startIcon={<ArrowBackIcon />}
        >
            {msgBack}
        </Button>
    );
};

BackButton.propTypes = {
    routerTo: PropTypes.string,
    routerOptions: PropTypes.object,
};

BackButton.defaultProps = {
    routerTo: '',
    routerOptions: {},
};

export default BackButton;
