import { formatGpu } from './formatGpu';

export const addNodeGPURows = (gpuEnabled, itemDefs) => {
    const itemDefinitions = itemDefs.slice();
    if (gpuEnabled) {
        itemDefinitions.splice(6, 0, {
            key: 'availableGpu',
            label: 'Available GPU',
            itemContentRenderer: /* istanbul ignore next */ (item) =>
                `${formatGpu(item.availableGpu)}`,
        });
        itemDefinitions.splice(7, 0, {
            key: 'allocatableGpu',
            label: 'Allocatable GPU',
            value: 'allocatableGpu',
        });
    }
    return itemDefinitions;
};

export const addVMGPURows = (gpuEnabled, itemDefs) => {
    const itemDefinitions = itemDefs.slice();
    if (gpuEnabled) {
        itemDefinitions.splice(10, 0, {
            key: 'gpu',
            label: 'GPU',
            value: 'gpu',
        });
    }
    return itemDefinitions;
};
