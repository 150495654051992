const defaults = {
    API_URL: process.env.REACT_APP_API_URL || process.env.PUBLIC_URL,
    BASE_PATH: process.env.PUBLIC_URL || '/web',
    MOCK_API: process.env.REACT_APP_MOCK_API === 'true',
};

const dev = {
    ...defaults,
    API_URL: 'http://localhost:8888',
};

const test = {
    ...dev,
    MOCK_API: true,
};

const prod = {
    ...defaults,
};

const envs = {
    dev,
    test,
    prod,
};

const detectEnv = () => {
    const orkaEnv = process.env.REACT_APP_ORKA_ENV;
    if (orkaEnv && envs[orkaEnv]) {
        return envs[orkaEnv];
    }

    if (process.env.NODE_ENV === 'development') {
        return envs.dev;
    }
    if (process.env.NODE_ENV === 'test') {
        return envs.test;
    }
    if (process.env.NODE_ENV === 'production') {
        return envs.prod;
    }

    return envs.prod;
};

const env = detectEnv();
export default env;
