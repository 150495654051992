import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import { Tabs, Tab, Paper } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Toolbar from '../../components/Toolbar';
import Loader from '../../components/Loader';
import RefreshButton from '../components/RefreshButton';
import BackButton from '../components/BackButton';
import VmsList from './components/VmsList';
import NodesList from './components/NodesList';
import PortsList from './components/PortsList';
import TabPanel from '../components/TabPanel';
import pages from '../pages';
import { messages } from './constants';
import { useVms } from './hooks';
import { useFeatures } from '../hooks';
import { handleError } from '../../utils/handleError';
import { getSelectedVms, getSelectedVmPorts } from './selectors';

const { msgVmDetails, msgTabGeneral, msgTabNode, msgTabReservedPorts } =
    messages;

const VmDetails = () => {
    const { vmId } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const [gpuEnabled, setGPUEnabled] = useState(false);
    const queryClient = useQueryClient();

    // queries
    const { data: features } = useFeatures();
    const { data, isFetching } = useVms({
        onError: (err) => toast.error(handleError(err)),
    });

    const selectedVms = getSelectedVms(data, [vmId]);

    const selectedVmPorts = getSelectedVmPorts(data, [vmId]);

    const selectedVmName = get(selectedVms[0], 'name');

    useEffect(() => {
        if (features && features.gpuPassthroughEnabled) {
            setGPUEnabled(true);
        }
    }, [features]);

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const handleRefresh = () => {
        queryClient.invalidateQueries('vms');
    };

    return (
        <>
            <BackButton
                routerTo={pages.vms.path}
                routerOptions={{ state: { selectedId: vmId } }}
            />

            <PageTitle
                primaryText={msgVmDetails}
                secondaryText={selectedVmName}
                noBottomMargin
            />

            <Toolbar rightContent={<RefreshButton onClick={handleRefresh} />} />

            <Paper elevation={1}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab label={msgTabGeneral} />
                    <Tab label={msgTabNode} />
                    <Tab label={msgTabReservedPorts} />
                </Tabs>

                <TabPanel value={selectedTab} index={0}>
                    <VmsList items={selectedVms} gpuEnabled={gpuEnabled} />
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                    <NodesList items={selectedVms} />
                </TabPanel>

                <TabPanel value={selectedTab} index={2}>
                    <PortsList items={selectedVmPorts} />
                </TabPanel>
            </Paper>

            {isFetching && <Loader />}
        </>
    );
};

export default VmDetails;
