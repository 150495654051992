import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { lighten, darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';

import { Chip } from './mui';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        '&.success': {
            backgroundColor: lighten(palette.success.main, 0.9),
            borderColor: palette.success.main,
            color: darken(palette.success.main, 0.6),
        },

        '&.error': {
            backgroundColor: lighten(palette.error.main, 0.9),
            borderColor: palette.error.main,
            color: darken(palette.error.main, 0.6),
        },

        '&.warning': {
            backgroundColor: lighten(palette.warning.main, 0.9),
            borderColor: palette.warning.main,
            color: darken(palette.warning.main, 0.6),
        },
    },
}));

const Badge = ({ variant, ...rest }) => {
    const classes = useStyles();

    return (
        <Chip
            className={cx(classes.root, {
                [variant]: variant,
            })}
            size="small"
            variant="outlined"
            {...rest}
        />
    );
};

Badge.propTypes = {
    variant: PropTypes.oneOf(['default', 'success', 'error', 'warning']),
};

Badge.defaultProps = {
    variant: 'default',
};

export default Badge;
