import { includes, filter, get, chain } from 'lodash';

import { findItemByKeyValue } from '../../utils/findItemByKeyValue';
import { extractVmPorts } from '../../utils/extractVmPorts';

export const getVmConfigs = (data) => get(data, 'items', []);

export const getSelectedVmConfigs = (data, ids) =>
    filter(getVmConfigs(data), ({ name }) => includes(ids, name));

export const getVms = (data) => get(data, 'items', []);

export const getSelectedVms = (data, names) =>
    filter(getVms(data), (item) => includes(names, get(item, 'name', '')));

export const getSelectedVmPorts = (data, ids) =>
    chain(getSelectedVms(data, ids))
        .flatMap((item) => extractVmPorts(item))
        .orderBy((item) => Number(item.guestPort))
        .value();

export const getUpdatedSelectedVm = (vms, selectedId) =>
    findItemByKeyValue(vms, 'name', selectedId);
