import { useQuery, useMutation } from 'react-query';

import {
    getImages,
    getRemoteImages,
    generateImage,
    pullRemoteImage,
    copyImage,
    deleteImage,
} from './queries';

export const useImages = (config) => useQuery('images', getImages, config);

export const useRemoteImages = (config) =>
    useQuery('images-remote', getRemoteImages, config);

export const useGenerateImage = (config) => useMutation(generateImage, config);

export const usePullRemoteImage = (config) =>
    useMutation(pullRemoteImage, config);

export const useCopyImage = (config) => useMutation(copyImage, config);

export const useDeleteImage = (config) => useMutation(deleteImage, config);
