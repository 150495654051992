import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, alpha } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';

const useStyles = makeStyles(
    ({ breakpoints, typography, palette, spacing }) => ({
        heroIcon: {
            marginTop: spacing[2],
            marginBottom: 0,
            '& i': {
                color: palette.text.primary,
            },
            [breakpoints.up('md')]: {
                '& i': {
                    color: palette.common.white,
                },
            },
        },
        heroTitle: {
            fontWeight: 500,
            [breakpoints.up('md')]: {
                color: palette.common.white,
            },
            '& h1': {
                margin: 0,
                marginBottom: typography.pxToRem(8),
            },
        },
        heroDescription: {
            [breakpoints.up('md')]: {
                color: alpha(palette.common.white, 0.6),
            },

            '& p': {
                marginTop: 0,
            },
        },
    }),
);

const HeroSection = ({ config }) => {
    const { icon, title, description } = config;
    const classes = useStyles();

    return (
        <>
            {icon && (
                <h1 className={classes.heroIcon}>
                    <i className={icon} />
                </h1>
            )}

            {title && (
                <RichText
                    className={classes.heroTitle}
                    Component="div"
                    render={title}
                />
            )}

            {description && (
                <RichText
                    className={classes.heroDescription}
                    Component="div"
                    render={description}
                />
            )}
        </>
    );
};

HeroSection.propTypes = {
    config: PropTypes.shape({
        theme: PropTypes.string,
        icon: PropTypes.string,
        title: PropTypes.array,
        description: PropTypes.array,
    }).isRequired,
};

export default HeroSection;
