import { useQuery, useMutation } from 'react-query';

import {
    getVms,
    getVmConfigs,
    createVm,
    deployVm,
    purgeVm,
    deleteVm,
    startVm,
    stopVm,
    suspendVm,
    resumeVm,
    revertVm,
    saveVmImage,
    commitVmImage,
} from './queries';

export const useVms = (config) => useQuery('vms', getVms, config);

export const useVmConfigs = (config) =>
    useQuery('vm-configs', getVmConfigs, config);

export const useCreateConfig = (config) => useMutation(createVm, config);

export const useDeployConfig = (config) => useMutation(deployVm, config);

export const usePurgeVm = (config) => useMutation(purgeVm, config);

export const useDeleteVm = (config) => useMutation(deleteVm, config);

export const useStartVm = (config) => useMutation(startVm, config);

export const useStopVm = (config) => useMutation(stopVm, config);

export const useSuspendVm = (config) => useMutation(suspendVm, config);

export const useResumeVm = (config) => useMutation(resumeVm, config);

export const useRevertVm = (config) => useMutation(revertVm, config);

export const useSaveVmImage = (config) => useMutation(saveVmImage, config);

export const useCommitVmImage = (config) => useMutation(commitVmImage, config);
