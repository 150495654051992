import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Tabs, Tab, Paper } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Toolbar from '../../components/Toolbar';
import Loader from '../../components/Loader';
import RefreshButton from '../components/RefreshButton';
import BackButton from '../components/BackButton';
import VmConfigsList from './components/VmConfigsList';
import TabPanel from '../components/TabPanel';
import { messages } from './constants';
import { useVmConfigs } from './hooks';
import { handleError } from '../../utils/handleError';
import { getSelectedVmConfigs } from './selectors';

const { msgVmDetails, msgTabGeneral } = messages;

const VmConfigDetails = () => {
    const { vmId } = useParams();

    const queryClient = useQueryClient();

    // queries
    const { data, isFetching } = useVmConfigs({
        onError: (err) => toast.error(handleError(err)),
    });

    const selectedVmConfigs = getSelectedVmConfigs(data, [vmId]);

    const handleRefresh = () => {
        queryClient.invalidateQueries('vm-configs');
    };

    return (
        <>
            <BackButton />

            <PageTitle
                primaryText={msgVmDetails}
                secondaryText={vmId}
                noBottomMargin
            />

            <Toolbar rightContent={<RefreshButton onClick={handleRefresh} />} />

            <Paper elevation={1}>
                <Tabs value={0}>
                    <Tab label={msgTabGeneral} />
                </Tabs>

                <TabPanel value={0} index={0}>
                    <VmConfigsList items={selectedVmConfigs} />
                </TabPanel>
            </Paper>

            {isFetching && <Loader />}
        </>
    );
};

export default VmConfigDetails;
