import apiClient from '../api';

export const getApiVersion = async () => {
    const { data } = await apiClient({
        url: '/api/v1/swagger/doc.json',
    });
    return data;
};

export const getFeatures = async () => {
    const { data } = await apiClient({
        url: '/api/v1/cluster-info',
    });
    return data;
};
