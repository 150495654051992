import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';

import AppRoutes from './AppRoutes';
import AppProviders from './AppProviders';
import UserProvider from './context/UserProvider';

import './app.scss';

library.add(fal);

const App = () => (
    <AppProviders>
        <UserProvider>
            <AppRoutes />
        </UserProvider>
    </AppProviders>
);

export default App;
