import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
    Tooltip,
    Grid,
    CardHeader,
    MuiHyperlink,
} from '../../../components/mui';
import Card from '../../../components/Card';
import Indicator from '../../../components/Indicator';
import { formatAny } from '../../../utils/formatAny';
import { getVariant } from '../../vms/components/VmsTable';
import ConnectionButton from '../../vms/components/ConnectionButton';

const useStyles = makeStyles(({ typography }) => ({
    indicator: {
        height: typography.pxToRem(10),
        minWidth: typography.pxToRem(10),
    },
}));

const VmsList = ({ items }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            {!isEmpty(items) &&
                items.map((item) => (
                    <Grid key={item.name} item xs={12} sm={12} md={6} lg={3}>
                        <Card variant={getVariant(item.status)}>
                            <CardHeader
                                avatar={
                                    <Tooltip title={item.status}>
                                        <span>
                                            <Indicator
                                                variant={getVariant(
                                                    item.status,
                                                )}
                                                className={classes.indicator}
                                            />
                                        </span>
                                    </Tooltip>
                                }
                                action={<ConnectionButton item={item} />}
                                title={
                                    <Tooltip title="Name">
                                        <MuiHyperlink
                                            to={`/web/vms/${item.name}`}
                                            component={Link}
                                            variant="h6"
                                            color="textPrimary"
                                        >
                                            {formatAny(item.name)}
                                        </MuiHyperlink>
                                    </Tooltip>
                                }
                                subheader={
                                    <Tooltip title="ID">
                                        <span>{formatAny(item.name)}</span>
                                    </Tooltip>
                                }
                            />
                        </Card>
                    </Grid>
                ))}
        </Grid>
    );
};

VmsList.propTypes = {
    items: PropTypes.array,
};

VmsList.defaultProps = {
    items: [],
};

export default VmsList;
