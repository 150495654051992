import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { noop } from 'lodash';

import { AppBar, IconButton, Toolbar, Hidden } from './mui';
import { sidebarWidth } from './Sidebar';

const useStyles = makeStyles(({ spacing, zIndex, breakpoints }) => ({
    appBar: {
        width: 'auto',
        zIndex: zIndex.drawer + 1,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)',
        left: 0,
        right: 0,
        [breakpoints.up('sm')]: {
            left: sidebarWidth,
        },
    },
    menuButton: {
        marginLeft: spacing(-2),
    },
    toolbar: {
        marginLeft: 'auto',
    },
}));

const Header = ({ onMenuToggle, headerContent }) => {
    const classes = useStyles();

    const handleMenuToggle = () => {
        onMenuToggle();
    };

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Hidden smUp implementation="css">
                    <IconButton
                        data-testid="open-sidebar"
                        color="inherit"
                        aria-label="Open menu"
                        className={classes.menuButton}
                        onClick={handleMenuToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>

                <div className={classes.toolbar}>{headerContent}</div>
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    onMenuToggle: PropTypes.func,
    headerContent: PropTypes.node,
};

Header.defaultProps = {
    onMenuToggle: noop,
    headerContent: null,
};

export default Header;
