import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import { Box } from './mui';

const Error = ({ message }) => (
    <Box mt={3} data-testid="error">
        <Alert severity="error">{message}</Alert>
    </Box>
);

Error.propTypes = {
    message: PropTypes.string,
};

Error.defaultProps = {
    message: '',
};

export default Error;
