import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, alpha } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';
import { camelCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import gradients from '../../../theme/gradients';

const useStyles = makeStyles(({ palette, typography }) => ({
    featureTitle: {
        color: palette.darkBlue.main,

        '& h3': {
            fontWeight: 500,
            marginTop: 0,
            marginBottom: typography.pxToRem(8),
        },
    },
    featureDescription: {
        color: palette.gray.main,

        '& p': {
            marginTop: 0,
            color: alpha(palette.common.black, 0.5),
        },
    },
    featureIcon: {
        fontSize: typography.pxToRem(32),
        fontWeight: 500,
        marginBottom: 0,
        '& i:before': {
            backgroundImage: ({ customTheme }) =>
                customTheme ? gradients[customTheme] : 'none',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            MozBackgroundClip: 'text',
            MozTextFillColor: 'transparent',
        },
        '& svg > path': {
            fill: ({ customTheme }) => `url(#${customTheme})`,
        },
    },
}));

const FeatureSection = ({ config }) => {
    const { icon, theme, title, description } = config;
    const props = {
        customTheme: camelCase(theme),
    };
    const classes = useStyles(props);

    return (
        <>
            {icon && (
                <h2 className={classes.featureIcon}>
                    {icon.includes('fak') ? (
                        <i className={icon} />
                    ) : (
                        <FontAwesomeIcon icon={icon} />
                    )}
                </h2>
            )}

            {title && (
                <RichText
                    className={classes.featureTitle}
                    Component="div"
                    render={title}
                />
            )}

            {description && (
                <RichText
                    className={classes.featureDescription}
                    Component="div"
                    render={description}
                />
            )}
        </>
    );
};

FeatureSection.propTypes = {
    config: PropTypes.shape({
        theme: PropTypes.string,
        icon: PropTypes.string,
        title: PropTypes.array,
        description: PropTypes.array,
    }).isRequired,
};

export default FeatureSection;
