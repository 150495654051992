import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { Container, Grid, Box } from '../../../components/mui';

import Logo from '../../../components/Logo';

const useStyles = makeStyles(({ palette, typography }) => ({
    background: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: palette.storm.main,
    },
    root: {
        height: '100vh',
        overflowX: 'hidden',
    },
    content: {
        position: 'relative',
        borderRadius: typography.pxToRem(8),
        backgroundColor: palette.common.white,
    },
}));

const AuthLayout = () => {
    const classes = useStyles();

    return (
        <Container>
            <Box className={classes.background} />

            <Grid
                container
                className={classes.root}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item container direction="column" lg={9}>
                    <Grid item>
                        <Logo />
                    </Grid>
                    <Grid item>
                        <Box p={2} className={classes.content}>
                            <Outlet />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AuthLayout;
