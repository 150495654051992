import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';

import { Typography, Grid, MuiHyperlink, Box } from './mui/index';
import { sidebarWidth } from './Sidebar';

export const textProps = {
    variant: 'caption',
    display: 'block',
    color: 'textSecondary',
};
export const legalUrl = 'https://www.macstadium.com/legal';
export const legal = `Terms / Privacy / Legal`;
export const copyright = `©${new Date().getFullYear()} MacStadium, Inc. is a U.S. corporation. MacStadium, Ltd. is registered in Ireland, company no.562354. MacStadium is not affiliated with Apple Inc. Apple®, Mac®, Mac mini®, Mac Pro®, iMac Pro®, and macOS® are trademarks of Apple Inc.`;

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: palette.background.default,
        padding: spacing(2),
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        [breakpoints.up('sm')]: {
            left: sidebarWidth,
            padding: spacing(3),
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
        },
    },
    leftContent: {},
    rightContent: {
        justifyContent: 'flex-start',
        [breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
}));

const Footer = ({ isMenuOpen }) => {
    const classes = useStyles();

    return (
        <Box
            className={cx(classes.root, {
                open: isMenuOpen,
            })}
            boxShadow={2}
        >
            <Grid container spacing={0} alignItems="flex-end">
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    className={classes.leftContent}
                >
                    <Typography {...textProps}>{copyright}</Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    className={classes.rightContent}
                >
                    <Box>
                        <Typography
                            {...textProps}
                            component={MuiHyperlink}
                            href={legalUrl}
                            target="_blank"
                            rel="noopener"
                        >
                            {legal}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

Footer.propTypes = {
    isMenuOpen: PropTypes.bool,
};

Footer.defaultProps = {
    isMenuOpen: false,
};

export default Footer;
