import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '../../components/mui';

const PageTitle = ({ primaryText, secondaryText, noBottomMargin }) => (
    <Box mt={1} mb={noBottomMargin ? 0 : 3}>
        {primaryText && <Typography variant="h5">{primaryText}</Typography>}
        {secondaryText && (
            <Typography variant="subtitle1" color="textSecondary">
                {secondaryText}
            </Typography>
        )}
    </Box>
);

PageTitle.propTypes = {
    primaryText: PropTypes.node,
    secondaryText: PropTypes.node,
    noBottomMargin: PropTypes.bool,
};

PageTitle.defaultProps = {
    primaryText: '',
    secondaryText: '',
    noBottomMargin: false,
};

export default PageTitle;
