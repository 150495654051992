import React, { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Add as AddIcon, Publish as PublishIcon } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { isEmpty, get } from 'lodash';

import { Box, DialogContentText } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Hint from '../components/Hint';
import Loader from '../../components/Loader';
import Toolbar from '../../components/Toolbar';
import RefreshButton from '../components/RefreshButton';
import ViewButton from '../components/ViewButton';
import MoreButton from '../components/MoreButton';
import Button from '../components/Button';
import CreateConfigDialog from './components/CreateConfigDialog';
import DeployConfigDialog from './components/DeployConfigDialog';
import VmConfigsTable from './components/VmConfigsTable';
import { messages } from './constants';
import { handleError } from '../../utils/handleError';
import { handleSuccess } from '../../utils/handleSuccess';
import { useFeatures } from '../hooks';
import { useVmConfigs, usePurgeVm } from './hooks';
import useConfirm from '../../context/useConfirm';
import { getVmConfigs } from './selectors';
import { formatHtml } from '../../utils/formatHtml';

const {
    msgConfirmPurgeConfig,
    msgVmConfigs,
    msgVmConfigsInfo,
    msgEmptyVmConfigs,
    msgVmConfigsHint,
    msgPurge,
    msgPurged,
    msgDeployConfig,
    msgCreateConfig,
} = messages;

const VmConfigs = () => {
    const confirm = useConfirm();
    const [selectedItem, setSelectedItem] = useState(null);
    const [showCreateConfigModal, setShowCreateConfigModal] = useState(false);
    const [showDeployConfigModal, setShowDeployConfigModal] = useState(false);
    const [gpuEnabled, setGPUEnabled] = useState(false);
    const queryClient = useQueryClient();

    const resetSelection = () => setSelectedItem(null);

    // queries
    const { data: features } = useFeatures();
    const { data, isLoading, isFetching } = useVmConfigs({
        onError: (err) => toast.error(handleError(err)),
    });

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('vm-configs');
        },
    };
    const { mutate: mutatePurgeVm } = usePurgeVm({
        ...mutateProps,
        onSuccess: (res) => {
            resetSelection();
            toast.success(handleSuccess(res, msgPurged));
        },
    });

    const vmConfigs = useMemo(() => getVmConfigs(data), [data]);

    useEffect(() => {
        if (features && features.gpuPassthroughEnabled) {
            setGPUEnabled(true);
        }
    }, [features]);

    const selectedVmConfigName = get(selectedItem, 'name', '');

    const handleRefresh = () => {
        queryClient.invalidateQueries('vm-configs');
    };

    const handleSelect = (item) => {
        setSelectedItem(item);
    };

    const handleToggleCreateConfig = () => {
        resetSelection();
        setShowCreateConfigModal(!showCreateConfigModal);
    };

    const handleToggleDeployConfig = () => {
        setShowDeployConfigModal(!showDeployConfigModal);
    };

    // actions
    const handlePurgeVm = async () => {
        if (
            await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmPurgeConfig(selectedVmConfigName),
                        )}
                    />
                ),
            })
        ) {
            await mutatePurgeVm({
                name: selectedVmConfigName,
            });
        }
    };

    return (
        <>
            <PageTitle
                primaryText={msgVmConfigs}
                secondaryText={msgVmConfigsInfo}
            />

            <Toolbar
                leftContent={
                    <>
                        <Button
                            data-testid="create"
                            color="primary"
                            title={msgCreateConfig}
                            onClick={handleToggleCreateConfig}
                            startIcon={<AddIcon />}
                        >
                            {msgCreateConfig}
                        </Button>

                        <Button
                            data-testid="deploy"
                            disabled={!isLoading && isEmpty(vmConfigs)}
                            color="primary"
                            title={msgDeployConfig}
                            onClick={handleToggleDeployConfig}
                            startIcon={<PublishIcon />}
                        >
                            {msgDeployConfig}
                        </Button>

                        <MoreButton
                            disabled={!selectedItem}
                            menuItems={[
                                {
                                    onClick: handlePurgeVm,
                                    dataTestId: 'purge',
                                    color: 'error',
                                    title: msgPurge,
                                },
                            ]}
                        />
                    </>
                }
                rightContent={
                    <>
                        <Box display="none">
                            <ViewButton disabled={!selectedItem} />
                        </Box>
                        <RefreshButton onClick={handleRefresh} />
                    </>
                }
            />

            {!isLoading && isEmpty(vmConfigs) && (
                <Hint variant="warning">{msgEmptyVmConfigs}</Hint>
            )}

            {!isLoading && !isEmpty(vmConfigs) && (
                <>
                    <VmConfigsTable
                        items={vmConfigs}
                        selectedItem={selectedItem}
                        onSelect={handleSelect}
                        gpuEnabled={gpuEnabled}
                    />

                    <Hint>{msgVmConfigsHint}</Hint>
                </>
            )}

            {(isFetching || isLoading) && <Loader />}

            <CreateConfigDialog
                isOpen={showCreateConfigModal}
                onClose={handleToggleCreateConfig}
                gpuEnabled={gpuEnabled}
            />

            <DeployConfigDialog
                selectedItem={selectedItem}
                isOpen={showDeployConfigModal}
                onClose={handleToggleDeployConfig}
                gpuEnabled={gpuEnabled}
                configs={vmConfigs}
            />
        </>
    );
};

export default VmConfigs;
