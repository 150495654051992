import pages from './pages';

const navItems = [
    {
        textPrimary: 'Overview',
        to: pages.overview.path,
    },
    {
        textLabel: 'Cluster',
        textPrimary: 'Nodes',
        to: pages.nodes.path,
    },
    {
        textLabel: 'Resources',
        textPrimary: 'VM Configs',
        to: pages.vmConfigs.path,
    },
    {
        textPrimary: 'VMs',
        to: pages.vms.path,
    },
    {
        textPrimary: 'Images',
        to: pages.images.path,
    },
    {
        textPrimary: 'ISOs',
        to: pages.isos.path,
    },
];

export default navItems;
