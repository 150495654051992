import apiClient from '../api';
import { defaultNamespace } from '../pages/constants';

export const loginUser = async ({ token }) => {
    await apiClient({
        url: `/api/v1/namespaces/${defaultNamespace}/nodes`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    return { token };
};

export const getUser = async ({ token }) => {
    await loginUser({ token });

    return {
        user: token,
        authenticated: true,
    };
};

export const createUser = async ({ email, password, licenseKey }) => {
    const { data } = await apiClient({
        url: '/users',
        method: 'POST',
        data: {
            email,
            password,
        },
        headers: {
            'Content-Type': 'application/json',
            'orka-licensekey': licenseKey,
        },
    });
    return data;
};
