const oceanBright =
    'linear-gradient(to bottom right, #6786f3 0%, #6dbaf2 50%, #83d9c4 100%);';
const oceanDark =
    'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(112deg, #6786f3 0%, #6dbaf2 50%, #83d9c4 100%)';
const passionBright =
    'linear-gradient(to bottom right, #7098ff 0%, #935eeb 50%, #e076e2 100%)';
const passionDark =
    'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(112deg, #a74da3 0%, #6f40aa 50%, #486bb8 100%)';
const fireBright =
    'linear-gradient(to bottom right, #f39a67 0%, #f26d81 50%, #d283d9 100%)';
const fireDark =
    'linear-gradient(to bottom right, #cf427c 0%, #e95f74 50%, #ff9d4d 100%)';
const stormBright =
    'linear-gradient(to bottom right, #413d72 0%, #7080e0 100%)';
const stormDark = 'linear-gradient(to bottom right, #323151 0%, #5558a3 100%)';

export default {
    oceanBright,
    oceanDark,
    passionBright,
    passionDark,
    fireBright,
    fireDark,
    stormBright,
    stormDark,
};
