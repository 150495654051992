import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer, Slide } from 'react-toastify';

import ConfirmProvider from './ConfirmProvider';

import theme from '../theme';

// https://fkhadra.github.io/react-toastify/installation
import 'react-toastify/dist/ReactToastify.css';

const CommonProviders = ({ children }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />

        <ConfirmProvider>
            {children}

            <ToastContainer
                hideProgressBar
                position="top-center"
                transition={Slide}
            />
        </ConfirmProvider>
    </ThemeProvider>
);

CommonProviders.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CommonProviders;
