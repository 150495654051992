import React, { useState, useMemo } from 'react';
import {
    Add as AddIcon,
    CloudDownload as CloudDownloadIcon,
    FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { find, isEmpty, get } from 'lodash';

import { Box, DialogContentText } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Hint from '../components/Hint';
import Loader from '../../components/Loader';
import Toolbar from '../../components/Toolbar';
import Button from '../components/Button';
import RefreshButton from '../components/RefreshButton';
import ViewButton from '../components/ViewButton';
import MoreButton from '../components/MoreButton';
import ImagesTable from './components/ImagesTable';
import { useImages, useDeleteImage } from './hooks';
import useConfirm from '../../context/useConfirm';
import GenerateImageDialog from './components/GenerateImageDialog';
import PullRemoteImageDialog from './components/PullRemoteImageDialog';
import CopyImageDialog from './components/CopyImageDialog';
import { messages } from './constants';
import { handleError } from '../../utils/handleError';
import { handleSuccess } from '../../utils/handleSuccess';
import { getImages } from './selectors';
import { formatHtml } from '../../utils/formatHtml';

const {
    msgImages,
    msgImagesInfo,
    msgEmptyImages,
    msgImagesHint,
    msgGenerate,
    msgPullRemote,
    msgDelete,
    msgDeleted,
    msgConfirmDelete,
    msgCopy,
} = messages;

const Images = () => {
    const confirm = useConfirm();
    const [showGenerateImageDialog, setShowGenerateImageDialog] =
        useState(false);
    const [showPullRemoteImageDialog, setShowPullRemoteImageDialog] =
        useState(false);
    const [showCopyImageDialog, setShowCopyImageDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const queryClient = useQueryClient();

    const resetSelection = () => setSelectedItem(null);

    const updateSelection = (res) => {
        const images = getImages(res);

        const updatedSelectedItem = find(
            images,
            (item) => get(item, 'name', '') === get(selectedItem, 'name', ''),
        );

        setSelectedItem(updatedSelectedItem || null);
    };

    // queries
    const { data, isLoading, isFetching } = useImages({
        onError: (err) => toast.error(handleError(err)),
        onSuccess: (res) => {
            updateSelection(res);
        },
    });

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('images');
        },
    };
    const { mutate: mutateDeleteImage } = useDeleteImage({
        ...mutateProps,
        onSuccess: (res) => {
            resetSelection();
            toast.success(handleSuccess(res, msgDeleted));
        },
    });

    const images = useMemo(() => getImages(data), [data]);

    const selectedImageName = get(selectedItem, 'name', '');

    const handleRefresh = () => {
        queryClient.invalidateQueries('images');
    };

    const handleSelect = (item) => {
        setSelectedItem(item);
    };

    const handleToggleGenerateImage = () => {
        resetSelection();
        setShowGenerateImageDialog(!showGenerateImageDialog);
    };

    const handleTogglePullRemoteImage = () => {
        setShowPullRemoteImageDialog(!showPullRemoteImageDialog);
    };

    const handleToggleCopyImage = () => {
        setShowCopyImageDialog(!showCopyImageDialog);
    };

    const handleDeleteImage = async () => {
        if (
            await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmDelete(selectedImageName),
                        )}
                    />
                ),
            })
        ) {
            await mutateDeleteImage({
                image: selectedImageName,
            });
        }
    };

    return (
        <>
            <PageTitle primaryText={msgImages} secondaryText={msgImagesInfo} />

            <Toolbar
                leftContent={
                    <>
                        <Button
                            data-testid="generate"
                            color="primary"
                            onClick={handleToggleGenerateImage}
                            title={`${msgGenerate} Image`}
                            startIcon={<AddIcon />}
                        >
                            {msgGenerate}
                        </Button>

                        <Button
                            data-testid="pull-remote"
                            color="primary"
                            onClick={handleTogglePullRemoteImage}
                            title={`${msgPullRemote} Image`}
                            startIcon={<CloudDownloadIcon />}
                        >
                            {msgPullRemote}
                        </Button>

                        <Button
                            data-testid="copy"
                            onClick={handleToggleCopyImage}
                            title={`${msgCopy} Image`}
                            disabled={!selectedItem}
                            startIcon={<FileCopyIcon />}
                        >
                            {msgCopy}
                        </Button>

                        <MoreButton
                            disabled={!selectedItem}
                            menuItems={[
                                {
                                    onClick: handleDeleteImage,
                                    dataTestId: 'delete',
                                    color: 'error',
                                    title: msgDelete,
                                },
                            ]}
                        />
                    </>
                }
                rightContent={
                    <>
                        <Box display="none">
                            <ViewButton disabled={!selectedItem} />
                        </Box>
                        <RefreshButton onClick={handleRefresh} />
                    </>
                }
            />

            {!isLoading && isEmpty(images) && (
                <Hint variant="warning">{msgEmptyImages}</Hint>
            )}

            {!isLoading && !isEmpty(images) && (
                <>
                    <ImagesTable
                        items={images}
                        selectedItem={selectedItem}
                        onSelect={handleSelect}
                    />

                    <Hint>{msgImagesHint}</Hint>
                </>
            )}

            {(isFetching || isLoading) && <Loader />}

            <GenerateImageDialog
                isOpen={showGenerateImageDialog}
                onClose={handleToggleGenerateImage}
            />

            <PullRemoteImageDialog
                isOpen={showPullRemoteImageDialog}
                onClose={handleTogglePullRemoteImage}
            />

            <CopyImageDialog
                selectedItem={selectedItem}
                isOpen={showCopyImageDialog}
                onClose={handleToggleCopyImage}
            />
        </>
    );
};

export default Images;
