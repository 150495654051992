import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';
import { ArrowRightAlt as ArrowRightAltIcon } from '@material-ui/icons';

import {
    CircularProgress,
    Box,
    Grid,
    MuiButton,
    Typography,
    MuiHyperlink,
} from '../../../components/mui';
import Form from '../../../components/Form';
import { validators, messages as commonMessages } from '../../constants';
import pages from '../../pages';
import { messages } from '../constants';

const {
    msgRequired,
    msgInvalidEmail,
    msgInvalidPassword,
    msgInvalidConfirmPassword,
    msgCreate,
    msgLogin,
} = commonMessages;
const { msgAlreadyHaveUser } = messages;
const { isValidEmail, isValidPassword } = validators;

const fields = [
    {
        name: 'email',
        type: 'email',
        label: 'Email *',
        initialValue: '',
    },
    {
        name: 'password',
        type: 'password',
        label: 'Password *',
        initialValue: '',
    },
    {
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password *',
        initialValue: '',
    },
    {
        name: 'licenseKey',
        label: 'License Key *',
        initialValue: '',
    },
];

const useStyles = makeStyles(
    ({ palette, typography, breakpoints, spacing }) => ({
        footer: {
            backgroundColor: palette.background.default,
            paddingLeft: spacing(6),
            paddingRight: spacing(6),
            paddingTop: spacing(3),
            paddingBottom: spacing(3),
            marginTop: spacing(2),
            borderRadius: typography.pxToRem(8),

            [breakpoints.down('sm')]: {
                backgroundColor: 'initial',
                padding: 0,
            },
        },
        footerContent: {
            [breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                textAlign: 'left',
                alignItems: 'flex-start',
            },
        },
        title: {
            fontWeight: 500,
            fontSize: typography.pxToRem(28),
        },
        hint: {
            [breakpoints.down('xs')]: {
                marginTop: spacing(2),
            },
        },
        submit: {
            textAlign: 'right',
        },
    }),
);

const RegisterForm = ({ children, isSubmitting, onSubmit }) => {
    const classes = useStyles();

    const handleValidate = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = msgRequired;
        } else if (!isValidEmail(values.email)) {
            errors.email = msgInvalidEmail;
        }

        if (!values.password) {
            errors.password = msgRequired;
        } else if (!isValidPassword(values.password)) {
            errors.password = msgInvalidPassword;
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = msgRequired;
        } else if (!isValidPassword(values.confirmPassword)) {
            errors.confirmPassword = msgInvalidPassword;
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = msgInvalidConfirmPassword;
        }

        if (!values.licenseKey) {
            errors.licenseKey = msgRequired;
        }

        return errors;
    };

    const handleSubmit = (values) => {
        onSubmit(values);
    };

    const formContentRenderer = () => (
        <Box className={classes.footer}>
            <Grid
                container
                alignItems="center"
                className={classes.footerContent}
            >
                <Grid item xs={12} md={9} className={classes.hint}>
                    <Typography color="textSecondary">
                        <MuiHyperlink to={pages.login.path} component={Link}>
                            <Box
                                component="span"
                                display="flex"
                                alignItems="center"
                            >
                                {msgAlreadyHaveUser} {msgLogin}
                                <ArrowRightAltIcon />
                            </Box>
                        </MuiHyperlink>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={3} className={classes.submit}>
                    <MuiButton
                        data-testid="submit"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        <Box display="flex" alignItems="center">
                            {isSubmitting && (
                                <Box mr={1}>
                                    <CircularProgress size="0.85rem" />
                                </Box>
                            )}

                            {msgCreate}
                        </Box>
                    </MuiButton>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
            formLeftTitleContentTemplate={
                <Box mt={1}>
                    <Typography className={classes.title} variant="h5">
                        {msgCreate}
                    </Typography>
                </Box>
            }
        >
            {children}
        </Form>
    );
};

RegisterForm.propTypes = {
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
};

RegisterForm.defaultProps = {
    isSubmitting: false,
    onSubmit: noop,
    children: undefined,
};

export default RegisterForm;
