import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(({ palette, typography }) => ({
    indicator: {
        position: 'relative',
        display: 'inline-block',
        backgroundColor: palette.text.secondary,
        color: palette.text.secondary,
        boxShadow: `0 0 0 2px ${palette.background.paper}`,
        height: typography.pxToRem(8),
        minWidth: typography.pxToRem(8),
        borderRadius: '50%',
        padding: 0,
        margin: 0,
        textAlign: 'center',

        '&:after': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '50%',
            border: '1px solid currentColor',
            animation: '$ripple 1.2s infinite ease-in-out',
            content: '""',
            transformOrigin: 'center',
        },

        '&.success': {
            backgroundColor: palette.success.main,
            borderColor: palette.success.main,
            color: palette.success.main,
        },

        '&.error': {
            backgroundColor: palette.error.main,
            color: palette.error.main,
        },

        '&.warning': {
            backgroundColor: palette.warning.main,
            color: palette.warning.main,
        },

        '&.disableAnimation:after': {
            animation: 'none',
        },
    },

    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const Indicator = ({ variant, disableAnimation, className }) => {
    const classes = useStyles();

    return (
        <span
            className={cx(classes.indicator, className, {
                [variant]: variant,
                disableAnimation,
            })}
        />
    );
};

Indicator.propTypes = {
    variant: PropTypes.oneOf(['default', 'success', 'error', 'warning']),
    disableAnimation: PropTypes.bool,
    className: PropTypes.string,
};

Indicator.defaultProps = {
    variant: 'default',
    disableAnimation: false,
    className: '',
};

export default Indicator;
