const regexIsoName = /\.(iso)$/;

export const validators = {
    isValidIsoName: (value) => regexIsoName.test(value),
};

export const messages = {
    msgIsos: 'ISOs',
    msgIsosInfo:
        'Orka ISOs are disk images used exclusively for the installation of macOS on a virtual machine. You must attach the ISO to the VM during deployment. After the installation is complete and the VM has booted successfully, you need to restart the VM to detach the ISO.',
    msgEmptyIsos: `You don't seem to have any ISOs available. Try pulling from the remote repo.`,
    msgIsosHint:
        'One ISO can be in use by one VM at a time. To release an ISO, complete the OS installation and restart the VM.',
    msgIsoDetails: 'ISO Details',
    msgTabGeneral: 'General',
    msgDelete: 'Delete',
    msgDeleted: 'ISO successfully deleted!',
    msgConfirmOverwrite: (name) =>
        `The provided name (<b>${name}</b>) is already in use. This will overwrite the existing ISO of that name and will result in loss of data.
        This operation cannot be reversed.`,
    msgConfirmDelete: (name) =>
        `This will delete the selected ISO (<b>${name}</b>). You will no longer be able to deploy VM configs that reference this ISO. You will not be able to complete any pending macOS installations that depend on this ISO.<br/><br/>
        This operation cannot be reversed.`,
    msgCopy: 'Copy',
    msgPull: 'Pull',
    msgPullRemote: 'Pull Remote',
    msgCopied: 'ISO successfully copied!',
    msgPulled: 'Remote ISO successfully pulled!',
    msgInvalidIsoName: 'Invalid ISO name. The file extension must be .iso`.',
};
