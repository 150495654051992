import apiClient, { timeout } from '../../api';
import { baseAPIUrl } from '../constants';

export const getVms = async () => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms`,
    });
    return data;
};

export const getVmConfigs = async () => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vmconfigs`,
    });
    return data;
};

export const purgeVm = async ({ name }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vmconfigs/${name}`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const deleteVm = async ({ name }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms/${name}`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const deployVm = async ({
    name,
    node,
    vnc,
    gpuPassthrough,
    isSiliconImage,
}) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms`,
        method: 'POST',
        timeout: isSiliconImage ? 0 : timeout,
        data: {
            vmConfig: name,
            node,
            vnc,
            gpuPassthrough,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const createVm = async ({
    name,
    image,
    cpu,
    vncConsole,
    gpuPassthrough,
    netBoost,
    memory,
}) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vmconfigs`,
        method: 'POST',
        data: {
            name,
            image,
            cpu,
            vncConsole,
            gpuPassthrough,
            netBoost,
            memory: parseInt(memory, 10),
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const startVm = async ({ name }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms/${name}/exec`,
        method: 'POST',
        data: {
            command: 'start',
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const stopVm = async ({ name }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms/${name}/exec`,
        method: 'POST',
        data: {
            command: 'stop',
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const suspendVm = async ({ name }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms/${name}/exec`,
        method: 'POST',
        data: {
            command: 'suspend',
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const resumeVm = async ({ name }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms/${name}/exec`,
        method: 'POST',
        data: {
            command: 'resume',
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const revertVm = async ({ name }) => {
    const { data } = await apiClient({
        url: `${baseAPIUrl}/vms/${name}/exec`,
        method: 'POST',
        data: {
            command: 'revert',
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const saveVmImage = async ({ name, newName }) => {
    const { data } = await apiClient({
        timeout: 0,
        url: `${baseAPIUrl}/vms/${name}/save`,
        method: 'POST',
        data: {
            imageName: newName,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};

export const commitVmImage = async ({ name }) => {
    const { data } = await apiClient({
        timeout: 0,
        url: `${baseAPIUrl}/vms/${name}/commit`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return data;
};
