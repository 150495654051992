import React from 'react';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import DropdownButton from './DropdownButton';
import { messages as commonMessages } from '../constants';

const { msgMore, msgMoreDetails } = commonMessages;

const MoreButton = ({ ...rest }) => (
    <DropdownButton data-testid="more" title={msgMoreDetails} {...rest}>
        {msgMore}
        <ExpandMoreIcon />
    </DropdownButton>
);

export default MoreButton;
