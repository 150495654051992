/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { CheckboxWithLabel } from 'formik-material-ui';

import {
    Grid,
    MenuItem,
    MuiButton,
    Box,
    CircularProgress,
} from '../../../components/mui';
import Form from '../../../components/Form';
import { messages as commonMessages } from '../../constants';
import { messages } from '../constants';
import { isAppleSiliconImageType } from '../../../utils/imageType';

const { msgRequired, msgCancel } = commonMessages;
const { msgDeploy, msgGpu, msgVnc, msgInvalidGpuAndVnc } = messages;

const DeployConfigForm = ({
    gpuEnabled,
    selectedItem,
    configs,
    nodes,
    onSubmit,
    onClose,
    isSubmitting,
}) => {
    const [isAppleSiliconImage, setIsAppleSiliconImage] = useState(false);

    const [vncConsole, setVncConsole] = useState(
        selectedItem?.vncConsole ?? true,
    );

    const [gpuPassthrough, setGpuPassthrough] = useState(
        selectedItem?.gpuPassthrough ?? false,
    );

    const [vmName, setVmName] = useState(selectedItem?.name ?? '');

    const handleValidate = async (values) => {
        const errors = {};

        const selectedConfig =
            values.name && configs.find((item) => item?.name === values.name);

        if (selectedConfig) {
            setVncConsole(selectedConfig.vncConsole ?? true);
            setGpuPassthrough(selectedConfig.gpuPassthrough ?? false);
            setVmName(selectedConfig.name);
            setIsAppleSiliconImage(
                isAppleSiliconImageType(selectedConfig.type),
            );
        }

        if (!values.name) {
            errors.name = msgRequired;
        }

        if (
            values.gpuPassthrough &&
            values.vncConsole &&
            !isAppleSiliconImage
        ) {
            errors.gpuPassthrough = msgInvalidGpuAndVnc;
        }
        return errors;
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = async (values) => {
        await onSubmit({
            ...values,
            gpuPassthrough:
                gpuEnabled && !isAppleSiliconImage && values.gpuPassthrough,
            vncConsole: isAppleSiliconImage || values.vncConsole,
            isSiliconImage: isAppleSiliconImage,
        });
    };

    const fields = [
        {
            select: true,
            name: 'name',
            label: 'VM Config *',
            SelectProps: {
                SelectDisplayProps: {
                    'data-testid': 'config-select',
                },
            },
            children:
                configs &&
                configs.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>
                )),
            initialValue: vmName,
        },
        {
            select: true,
            name: 'node',
            label: 'Node (optional)',
            SelectProps: {
                SelectDisplayProps: {
                    'data-testid': 'node-select',
                },
            },
            children:
                nodes &&
                nodes.map(({ name, value, isReady }) => (
                    <MenuItem
                        key={name}
                        value={value ?? ''}
                        disabled={!isReady}
                    >
                        {name}
                    </MenuItem>
                )),
            initialValue: '',
        },
        {
            component: CheckboxWithLabel,
            name: 'vncConsole',
            type: 'checkbox',
            Label: { label: msgVnc },
            initialValue: vncConsole,
            shouldShowError: true,
            initialTouched: true,
            hide: isAppleSiliconImage,
        },
        {
            component: CheckboxWithLabel,
            name: 'gpuPassthrough',
            type: 'checkbox',
            Label: { label: msgGpu },
            initialValue: gpuPassthrough,
            shouldShowError: true,
            initialTouched: true,
            hide: !gpuEnabled || isAppleSiliconImage,
        },
    ];

    const formContentRenderer = useCallback(
        () => (
            <Box mt={2} mb={1}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        {isSubmitting && (
                            <Box data-testid="loading">
                                <CircularProgress size="1.5rem" />
                            </Box>
                        )}
                    </Grid>
                    <Grid item>
                        <MuiButton
                            data-testid="cancel"
                            onClick={handleCancel}
                            disabled={isSubmitting}
                        >
                            {msgCancel}
                        </MuiButton>
                        <MuiButton
                            data-testid="submit"
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            {msgDeploy}
                        </MuiButton>
                    </Grid>
                </Grid>
            </Box>
        ),
        [isSubmitting],
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
        />
    );
};

DeployConfigForm.propTypes = {
    gpuEnabled: PropTypes.bool,
    selectedItem: PropTypes.object,
    configs: PropTypes.arrayOf(PropTypes.object),
    nodes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.any,
            isReady: PropTypes.bool,
        }),
    ),
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

DeployConfigForm.defaultProps = {
    gpuEnabled: false,
    selectedItem: null,
    configs: [],
    nodes: [],
    onSubmit: noop,
    onClose: noop,
    isSubmitting: false,
};

export default DeployConfigForm;
