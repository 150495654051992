import { useContext } from 'react';

import UserContext from './UserContext';

const useUser = () => {
    const context = useContext(UserContext);

    /* istanbul ignore next */
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider.');
    }
    return context;
};

export default useUser;
