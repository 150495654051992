import axios from 'axios';

import config from '../config';
import { getToken } from '../context/persister';

export const timeout = 60000;

const apiClient = axios.create({
    baseURL: config.API_URL,
    timeout,
});

apiClient.interceptors.request.use((cfg) => {
    const token = getToken();

    if (token) {
        // eslint-disable-next-line no-param-reassign
        cfg.headers.Authorization = `Bearer ${token}`;
    }

    return cfg;
});

export default apiClient;
