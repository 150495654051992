import { createTheme } from '@material-ui/core/styles';

import palette from './palette';

const theme = createTheme({
    palette: {
        text: {
            primary: palette.gray[700],
        },
        background: {
            default: palette.gray[100],
        },
        gray: {
            main: palette.gray[500],
        },
        primary: {
            main: palette.indigo[500],
        },
        secondary: {
            main: palette.gray[500],
        },
        info: {
            main: palette.blue[500],
        },
        success: {
            main: palette.green[500],
        },
        error: {
            main: palette.red[500],
        },
        warning: {
            main: palette.yellow[500],
        },

        // custom
        brand: {
            main: palette.orange[500],
        },
        brandAlternate: {
            main: palette.purple[500],
        },
        navy: { main: '#1e1e37' },
        storm: { main: '#323151' },
        darkBlue: { main: '#2d3f51' },
    },
    typography: {
        fontFamily: '"Inter", sans-serif',
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiTextField: {
            fullWidth: true,
        },
        MuiDialog: {
            maxWidth: 'xs',
        },
    },
    overrides: {
        MuiLink: {
            root: {
                '&:hover': {
                    underline: 'always',
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.75rem',
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#fff',
                color: palette.gray[700],
            },
        },
    },
});

export default theme;
