import { useMemo } from 'react';
import { get } from 'lodash';

export const useDiscoverySectionsMapping = (data) =>
    useMemo(() => {
        const hero = {
            icon: data?.page_icon,
            theme: get(data, 'theme._meta.uid'),
            title: data?.title,
            description: data?.description,
            ctaDescription: data?.cta_box_description,
            ctaLabel: data?.cta_label,
            ctaLink: data?.cta_link_url,
        };

        const features = data?.feature_grid.map(
            ({
                section_feature_icon: icon,
                section_feature_theme: theme,
                section_feature_title: title,
                section_feature_description: description,
            }) => ({
                icon,
                theme: get(theme, '_meta.uid'),
                title,
                description,
            }),
        );

        return { hero, features };
    }, [data]);
