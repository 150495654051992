import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, includes } from 'lodash';

import {
    Grid,
    MenuItem,
    MuiButton,
    Box,
    CircularProgress,
    DialogContentText,
} from '../../../components/mui';
import Form from '../../../components/Form';
import Hint from '../../components/Hint';
import useConfirm from '../../../context/useConfirm';
import { messages as commonMessages } from '../../constants';
import { messages } from '../constants';
import { formatHtml } from '../../../utils/formatHtml';

const { msgRequired, msgCancel, msgLongerOperationHint } = commonMessages;
const { msgPull, msgConfirmOverwrite } = messages;

const PullRemoteImageForm = ({
    images,
    remoteImages,
    onSubmit,
    onClose,
    isSubmitting,
}) => {
    const confirm = useConfirm();

    const handleValidate = (values) => {
        const errors = {};

        if (!values.image) {
            errors.image = msgRequired;
        }

        return errors;
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = async (values) => {
        let isConfirmed = true;

        const imageRemoteName = get(values, 'image', '');
        const imageNewName = get(values, 'newName', '');

        if (
            (imageNewName && includes(images, imageNewName)) ||
            (!imageNewName && includes(images, imageRemoteName))
        ) {
            isConfirmed = await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmOverwrite(
                                imageNewName || imageRemoteName,
                            ),
                        )}
                    />
                ),
            });
        }

        if (isConfirmed) {
            await onSubmit(values);
        }
    };

    const fields = [
        {
            select: true,
            name: 'image',
            label: 'Image *',
            SelectProps: {
                SelectDisplayProps: {
                    'data-testid': 'image',
                },
            },
            children:
                remoteImages &&
                remoteImages.map((image) => (
                    <MenuItem key={image} value={image}>
                        {image}
                    </MenuItem>
                )),
            initialValue: '',
        },
        {
            name: 'newName',
            label: 'New Name (optional)',
            initialValue: '',
        },
    ];

    const formContentRenderer = () => (
        <Box mt={2} mb={1}>
            <Box mb={2}>
                <Hint variant="warning" fullWidth>
                    {msgLongerOperationHint}
                </Hint>
            </Box>
            <Grid container alignItems="center">
                <Grid item xs>
                    {isSubmitting && (
                        <Box data-testid="loading">
                            <CircularProgress size="1.5rem" />
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <MuiButton
                        data-testid="cancel"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        {msgCancel}
                    </MuiButton>
                    <MuiButton
                        data-testid="submit"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {msgPull}
                    </MuiButton>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
        />
    );
};

PullRemoteImageForm.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    remoteImages: PropTypes.arrayOf(PropTypes.string),
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

PullRemoteImageForm.defaultProps = {
    images: [],
    remoteImages: [],
    onSubmit: noop,
    onClose: noop,
    isSubmitting: false,
};

export default PullRemoteImageForm;
