import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Table from '../../../components/Table';
import { formatDate } from '../../../utils/formatDate';

const itemDefs = [
    {
        key: 'name',
        label: 'Name',
        value: 'name',
    },
    {
        key: 'size',
        label: 'Size',
        value: 'size',
    },
    {
        key: 'modified',
        label: 'Last Modified',
        itemContentRenderer: (item) => formatDate(item.modified),
    },
];

const IsosTable = ({ items, selectedItem, onSelect }) => (
    <Table
        primaryKey="name"
        items={items}
        itemDefinitions={itemDefs}
        selectedItem={selectedItem}
        onSelect={onSelect}
    />
);

IsosTable.propTypes = {
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    onSelect: PropTypes.func,
};

IsosTable.defaultProps = {
    items: [],
    selectedItem: null,
    onSelect: noop,
};

export default IsosTable;
