import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, alpha } from '@material-ui/core';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from './mui';
import PartiallyActiveNavLink from '../pages/components/router/PartiallyActiveNavLink';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
    listItem: {
        '&:hover': {
            backgroundColor: alpha('#fff', 0.08),
        },
        '&.active': {
            backgroundColor: alpha('#fff', 0.08),
            color: palette.brand.main,
        },
    },
    listItemLabel: {
        textTransform: 'uppercase',
        fontSize: typography.pxToRem(13),
        marginLeft: spacing(1),
        color: palette.gray.main,
    },
    listItemText: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        fontSize: typography.pxToRem(16),
    },
}));

const SidebarNav = ({ items }) => {
    const classes = useStyles();

    const renderItem = ({
        to,
        disabled,
        icon: Icon,
        textLabel,
        textPrimary,
        textSecondary,
    }) => (
        <>
            {textLabel && (
                <ListSubheader
                    component="span"
                    className={classes.listItemLabel}
                >
                    {textLabel}
                </ListSubheader>
            )}

            <ListItem
                button
                component={PartiallyActiveNavLink}
                to={to}
                className={classes.listItem}
                disabled={disabled}
            >
                {Icon && (
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                )}

                <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={textPrimary}
                    secondary={textSecondary}
                />
            </ListItem>
        </>
    );

    return (
        <List>
            {items.map((item) => (
                <div key={item.to}>{renderItem(item)}</div>
            ))}
        </List>
    );
};

SidebarNav.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string,
            textLabel: PropTypes.string,
            textPrimary: PropTypes.string,
            textSecondary: PropTypes.string,
            disabled: PropTypes.bool,
            icon: PropTypes.any,
        }),
    ),
};

SidebarNav.defaultProps = {
    items: [],
};

export default SidebarNav;
