import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { CheckboxWithLabel } from 'formik-material-ui';

import {
    MenuItem,
    Grid,
    MuiButton,
    Box,
    CircularProgress,
} from '../../../components/mui';
import Form from '../../../components/Form';
import { messages as commonMessages } from '../../constants';
import { messages, validators, defaultCpuCoresNumber } from '../constants';
import { isAppleSiliconImageType } from '../../../utils/imageType';

const { msgRequired, msgCreate, msgCancel } = commonMessages;
const {
    msgVnc,
    msgGpu,
    msgNetBoost,
    msgInvalidVmName,
    msgInvalidVmNameLength,
    msgInvalidCoresNumber,
    msgInvalidGpuAndVnc,
    msgInvalidMemoryNumber,
} = messages;

const {
    isValidVmName,
    isValidVmNameLength,
    isValidCoresNumber,
    isValidMemoryNumber,
} = validators;

const CreateConfigForm = ({ gpuEnabled, images, onSubmit, onClose }) => {
    const [isAppleSiliconImage, setIsAppleSiliconImage] = useState(false);

    const handleValidate = (values) => {
        const { image } = values;

        const errors = {};

        if (!values.name) {
            errors.name = msgRequired;
        } else if (!isValidVmName(values.name)) {
            errors.name = msgInvalidVmName;
        } else if (!isValidVmNameLength(values.name)) {
            errors.name = msgInvalidVmNameLength;
        }

        if (!image) {
            errors.image = msgRequired;
        }

        const appleSiliconImage = image && isAppleSiliconImageType(image.type);

        if (!values.cpu) {
            errors.cpu = msgRequired;
        } else if (!isValidCoresNumber(values.cpu)) {
            errors.cpu = msgInvalidCoresNumber;
        }

        if (values.gpuPassthrough && values.vncConsole && !appleSiliconImage) {
            errors.gpuPassthrough = msgInvalidGpuAndVnc;
        }

        if (
            values.memory !== undefined &&
            values.memory !== '' &&
            !isValidMemoryNumber(values.memory)
        ) {
            errors.memory = msgInvalidMemoryNumber;
        }

        setIsAppleSiliconImage(appleSiliconImage);

        return errors;
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = async (values) => {
        await onSubmit({
            ...values,
            gpuPassthrough:
                gpuEnabled && !isAppleSiliconImage && values.gpuPassthrough,
            vncConsole: isAppleSiliconImage || values.vncConsole,
        });
    };

    const fields = [
        {
            name: 'name',
            label: 'Name *',
            initialValue: '',
        },
        {
            select: true,
            name: 'image',
            label: 'Base Image *',
            SelectProps: {
                SelectDisplayProps: {
                    'data-testid': 'image-select',
                },
            },
            children:
                images &&
                images.map((image) => (
                    <MenuItem key={image.name} value={image}>
                        {image.name}
                    </MenuItem>
                )),
            initialValue: '',
        },
        {
            name: 'cpu',
            type: 'number',
            label: 'CPU *',
            initialValue: defaultCpuCoresNumber,
        },
        {
            name: 'memory',
            type: 'number',
            label: 'Memory (G)',
            initialValue: '',
        },
        {
            component: CheckboxWithLabel,
            name: 'vncConsole',
            type: 'checkbox',
            Label: { label: msgVnc },
            initialValue: true,
            shouldShowError: true,
            hide: isAppleSiliconImage,
        },
        {
            component: CheckboxWithLabel,
            name: 'gpuPassthrough',
            type: 'checkbox',
            Label: { label: msgGpu },
            initialValue: false,
            shouldShowError: true,
            initialTouched: true,
            hide: !gpuEnabled || isAppleSiliconImage,
        },
        {
            component: CheckboxWithLabel,
            name: 'netBoost',
            type: 'checkbox',
            Label: { label: msgNetBoost },
            initialValue: true,
            shouldShowError: true,
            hide: isAppleSiliconImage,
        },
    ];

    const formContentRenderer = ({ isSubmitting }) => (
        <Box mt={2} mb={1}>
            <Grid container alignItems="center">
                <Grid item xs>
                    {isSubmitting && (
                        <Box data-testid="loading">
                            <CircularProgress size="1.5rem" />
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <MuiButton
                        data-testid="cancel"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        {msgCancel}
                    </MuiButton>
                    <MuiButton
                        data-testid="submit"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {msgCreate}
                    </MuiButton>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
        />
    );
};

CreateConfigForm.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    gpuEnabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

CreateConfigForm.defaultProps = {
    images: [],
    gpuEnabled: false,
    onSubmit: noop,
    onClose: noop,
};

export default CreateConfigForm;
