import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, lowerCase } from 'lodash';
import { Link } from 'react-router-dom';

import { MuiHyperlink } from '../../../components/mui';
import Table from '../../../components/Table';
import Status from '../../components/Status';
import { formatIpv4 } from '../../../utils/ip';
import { formatAny } from '../../../utils/formatAny';
import { addNodeGPUColumn } from '../../../utils/addGPUColumn';
import { NODE_STATES } from '../enums';
import { messages } from '../constants';

const { msgNodeStateReady, msgNodeStateNotReady, msgNodeStateSandbox } =
    messages;

const MAP_NODE_STATES_TYPES = {
    [NODE_STATES.READY]: 'success',
    [NODE_STATES.NOT_READY]: 'error',
    [NODE_STATES.SANDBOX]: 'warning',
};

const MAP_NODE_STATES_TITLES = {
    [NODE_STATES.READY]: msgNodeStateReady,
    [NODE_STATES.NOT_READY]: msgNodeStateNotReady,
    [NODE_STATES.SANDBOX]: msgNodeStateSandbox,
};

export const getTitle = (state) =>
    MAP_NODE_STATES_TITLES[lowerCase(state)] || '';
const getVariant = (state) =>
    MAP_NODE_STATES_TYPES[lowerCase(state)] || 'default';
export const getLabel = (state) => formatAny(lowerCase(state));

const itemDefs = [
    {
        key: 'name',
        label: 'Name',
        itemContentRenderer: (item) => (
            <MuiHyperlink to={get(item, 'name', '')} component={Link}>
                {formatAny(item.name)}
            </MuiHyperlink>
        ),
    },
    {
        key: 'address',
        label: 'Address',
        itemContentRenderer: (item) => formatIpv4(formatAny(item.nodeIP)),
    },
    {
        key: 'summaryCpu',
        label: 'Available Cores',
        itemContentRenderer: (item) =>
            `${formatAny(item.availableCpu)} / ${formatAny(
                item.allocatableCpu,
            )}`,
    },
    {
        key: 'summaryMemory',
        label: 'Available Memory',
        itemContentRenderer: (item) =>
            `${formatAny(item.availableMemory)} / ${formatAny(
                item.allocatableMemory,
            )}`,
    },
    {
        key: 'nodeType',
        label: 'Type',
        itemContentRenderer: (item) => item.nodeType,
    },
    {
        key: 'phase',
        label: 'State',
        itemContentRenderer: (item) => {
            const phase = get(item, 'phase', '');
            const nodeType = get(item, 'nodeType', '');

            const title =
                nodeType === 'SANDBOX'
                    ? getTitle(NODE_STATES.SANDBOX)
                    : getTitle(phase);

            const label = getLabel(phase);
            const variant =
                nodeType === 'SANDBOX'
                    ? getVariant(NODE_STATES.SANDBOX)
                    : getVariant(phase);

            return <Status title={title} label={label} variant={variant} />;
        },
    },
];

const NodesTable = ({ items, selectedItem, onSelect, gpuEnabled }) => {
    const itemDefinitions = addNodeGPUColumn(gpuEnabled, itemDefs);
    return (
        <Table
            items={items}
            itemDefinitions={itemDefinitions}
            selectedItem={selectedItem}
            onSelect={onSelect}
        />
    );
};

NodesTable.propTypes = {
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    onSelect: PropTypes.func,
    gpuEnabled: PropTypes.bool,
};

NodesTable.defaultProps = {
    items: [],
    selectedItem: null,
    onSelect: noop,
    gpuEnabled: false,
};

export default NodesTable;
