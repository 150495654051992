import React, { useState, useMemo } from 'react';
import {
    CloudDownload as CloudDownloadIcon,
    FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { find, isEmpty, get } from 'lodash';

import { Box, DialogContentText } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Hint from '../components/Hint';
import Loader from '../../components/Loader';
import Toolbar from '../../components/Toolbar';
import Button from '../components/Button';
import RefreshButton from '../components/RefreshButton';
import ViewButton from '../components/ViewButton';
import MoreButton from '../components/MoreButton';
import IsosTable from './components/IsosTable';
import { useIsos, useDeleteIso } from './hooks';
import useConfirm from '../../context/useConfirm';
import PullRemoteIsoDialog from './components/PullRemoteIsoDialog';
import CopyIsoDialog from './components/CopyIsoDialog';
import { messages } from './constants';
import { handleError } from '../../utils/handleError';
import { handleSuccess } from '../../utils/handleSuccess';
import { getIsos } from './selectors';
import { formatHtml } from '../../utils/formatHtml';

const {
    msgIsos,
    msgIsosInfo,
    msgEmptyIsos,
    msgIsosHint,
    msgPullRemote,
    msgDelete,
    msgDeleted,
    msgConfirmDelete,
    msgCopy,
} = messages;

const Isos = () => {
    const confirm = useConfirm();
    const [showPullRemoteIsoDialog, setShowPullRemoteIsoDialog] =
        useState(false);
    const [showCopyIsoDialog, setShowCopyIsoDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const queryClient = useQueryClient();

    const resetSelection = () => setSelectedItem(null);

    const updateSelection = (res) => {
        const isos = getIsos(res);

        const updatedSelectedItem = find(
            isos,
            (item) => get(item, 'name', '') === get(selectedItem, 'name', ''),
        );

        setSelectedItem(updatedSelectedItem || null);
    };

    // queries
    const { data, isLoading, isFetching } = useIsos({
        onError: (err) => toast.error(handleError(err)),
        onSuccess: (res) => {
            updateSelection(res);
        },
    });

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('isos');
        },
    };
    const { mutate: mutateDeleteIso } = useDeleteIso({
        ...mutateProps,
        onSuccess: (res) => {
            resetSelection();
            toast.success(handleSuccess(res, msgDeleted));
        },
    });

    const isos = useMemo(() => getIsos(data), [data]);

    const selectedIsoName = get(selectedItem, 'name', '');

    const handleRefresh = () => {
        queryClient.invalidateQueries('isos');
    };

    const handleSelect = (item) => {
        setSelectedItem(item);
    };

    const handleTogglePullRemoteIso = () => {
        setShowPullRemoteIsoDialog(!showPullRemoteIsoDialog);
    };

    const handleToggleCopyIso = () => {
        setShowCopyIsoDialog(!showCopyIsoDialog);
    };

    const handleDeleteIso = async () => {
        if (
            await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmDelete(selectedIsoName),
                        )}
                    />
                ),
            })
        ) {
            await mutateDeleteIso({
                iso: selectedIsoName,
            });
        }
    };

    return (
        <>
            <PageTitle primaryText={msgIsos} secondaryText={msgIsosInfo} />

            <Toolbar
                leftContent={
                    <>
                        <Button
                            data-testid="pull-remote"
                            color="primary"
                            onClick={handleTogglePullRemoteIso}
                            title={`${msgPullRemote} ISO`}
                            startIcon={<CloudDownloadIcon />}
                        >
                            {msgPullRemote}
                        </Button>

                        <Button
                            data-testid="copy"
                            onClick={handleToggleCopyIso}
                            title={`${msgCopy} ISO`}
                            disabled={!selectedItem}
                            startIcon={<FileCopyIcon />}
                        >
                            {msgCopy}
                        </Button>

                        <MoreButton
                            disabled={!selectedItem}
                            menuItems={[
                                {
                                    onClick: handleDeleteIso,
                                    dataTestId: 'delete',
                                    color: 'error',
                                    title: msgDelete,
                                },
                            ]}
                        />
                    </>
                }
                rightContent={
                    <>
                        <Box display="none">
                            <ViewButton disabled={!selectedItem} />
                        </Box>
                        <RefreshButton onClick={handleRefresh} />
                    </>
                }
            />

            {!isLoading && isEmpty(isos) && (
                <Hint variant="warning">{msgEmptyIsos}</Hint>
            )}

            {!isLoading && !isEmpty(isos) && (
                <>
                    <IsosTable
                        items={isos}
                        selectedItem={selectedItem}
                        onSelect={handleSelect}
                    />

                    <Hint>{msgIsosHint}</Hint>
                </>
            )}

            {(isFetching || isLoading) && <Loader />}

            <PullRemoteIsoDialog
                isOpen={showPullRemoteIsoDialog}
                onClose={handleTogglePullRemoteIso}
            />

            <CopyIsoDialog
                selectedItem={selectedItem}
                isOpen={showCopyIsoDialog}
                onClose={handleToggleCopyIso}
            />
        </>
    );
};

export default Isos;
