export const prismicOrka = [
    {
        node: {
            page_icon: 'fak fa-orkawhale',
            title: [
                {
                    type: 'heading1',
                    text: 'Orchestration with Kubernetes on Apple.',
                    spans: [],
                },
            ],
            description: [
                {
                    type: 'paragraph',
                    text: 'Orka orchestrates macOS in a cloud environment using Kubernetes technology on genuine Apple hardware.',
                    spans: [],
                },
            ],
            cta_box_description: [
                {
                    type: 'paragraph',
                    text: 'Our sales engineers are available to provide a price quote or answer your questions about orchestrating your builds with Orka.',
                    spans: [],
                },
            ],
            cta_label: 'Contact sales',
            cta_link_url: 'https://www.macstadium.com/contact-sales',
            _linkType: 'Link.document',
            theme: {
                _meta: {
                    uid: 'ocean-dark',
                },
            },
            feature_grid: [
                {
                    section_feature_icon: 'fak fa-vm-imac',
                    section_feature_theme: {
                        _meta: {
                            uid: 'fire-bright',
                        },
                    },
                    section_feature_title: [
                        {
                            type: 'heading3',
                            text: 'Containerized macOS.',
                            spans: [],
                        },
                    ],
                    section_feature_description: [
                        {
                            type: 'paragraph',
                            text: 'Orka is specifically designed for Mac but based on standard cloud orchestration tools so Apple OS development teams can use container technology features just like they do for non-Apple workloads.',
                            spans: [],
                        },
                    ],
                },
                {
                    section_feature_icon: 'fa-light fa-rectangle-terminal',
                    section_feature_theme: {
                        _meta: {
                            uid: 'ocean-bright',
                        },
                    },
                    section_feature_title: [
                        {
                            type: 'heading3',
                            text: 'Developer focused.',
                            spans: [],
                        },
                    ],
                    section_feature_description: [
                        {
                            type: 'paragraph',
                            text: 'Orka users get a software-driven, reliable, and high-performance experience with Apple hardware. Integrated with popular CI tools, Orka lets developers use the tools they already know and love.',
                            spans: [],
                        },
                    ],
                },
                {
                    section_feature_icon: 'fak fa-k8',
                    section_feature_theme: {
                        _meta: {
                            uid: 'passion-bright',
                        },
                    },
                    section_feature_title: [
                        {
                            type: 'heading3',
                            text: 'Leverage Kubernetes.',
                            spans: [],
                        },
                    ],
                    section_feature_description: [
                        {
                            type: 'paragraph',
                            text: 'Spin up a VM in seconds with any version of macOS, then orchestrate pods of those VMs across a Kubernetes cluster. Drive Mac infrastructure with built-in tools like kubectl, Kubernetes Dashboard, and Autoscaler.',
                            spans: [],
                        },
                    ],
                },
                {
                    section_feature_icon: 'fa-light fa-book-open-reader',
                    section_feature_theme: {
                        _meta: {
                            uid: 'storm-bright',
                        },
                    },
                    section_feature_title: [
                        {
                            type: 'heading3',
                            text: 'Learn more.',
                            spans: [],
                        },
                    ],
                    section_feature_description: [
                        {
                            type: 'paragraph',
                            text: 'Want to learn more about Mac private clouds with Orka virtualization? Visit our Orka product page.',
                            spans: [
                                {
                                    start: 80,
                                    end: 97,
                                    type: 'hyperlink',
                                    data: {
                                        link_type: 'Web',
                                        url: 'https://www.macstadium.com/orka',
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    section_feature_icon: 'fa-light fa-book',
                    section_feature_theme: {
                        _meta: {
                            uid: 'ocean-bright',
                        },
                    },
                    section_feature_title: [
                        {
                            type: 'heading3',
                            text: 'Read the docs.',
                            spans: [],
                        },
                    ],
                    section_feature_description: [
                        {
                            type: 'paragraph',
                            text: 'Dig deeper into how Orka works, its CLI and API, and more by visiting our documentation site.',
                            spans: [
                                {
                                    start: 74,
                                    end: 92,
                                    type: 'hyperlink',
                                    data: {
                                        link_type: 'Web',
                                        url: 'https://orkadocs.macstadium.com',
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
];
