import { get } from 'lodash';

export const fallbackMessage = 'Success!';

export const handleSuccess = (data, message) => {
    if (message) {
        return message;
    }

    return get(data, 'message', fallbackMessage);
};
