import React from 'react';
import PropTypes from 'prop-types';
import { noop, get } from 'lodash';

import {
    Grid,
    MuiButton,
    Box,
    CircularProgress,
} from '../../../components/mui';
import Form from '../../../components/Form';
import { messages as commonMessages } from '../../constants';
import { messages, validators } from '../constants';

const { msgRequired, msgCancel } = commonMessages;
const { msgCopy, msgInvalidIsoName } = messages;
const { isValidIsoName } = validators;

const CopyIsoForm = ({ selectedItem, onSubmit, onClose }) => {
    const handleValidate = (values) => {
        const errors = {};

        if (!values.newName) {
            errors.newName = msgRequired;
        } else if (!isValidIsoName(values.newName)) {
            errors.newName = msgInvalidIsoName;
        }

        return errors;
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = async (values) => {
        await onSubmit(values);
    };

    const fields = [
        {
            name: 'newName',
            label: 'New Name *',
            initialValue: get(selectedItem, 'name', ''),
        },
    ];

    const formContentRenderer = ({ isSubmitting }) => (
        <Box mt={2} mb={1}>
            <Grid container alignItems="center">
                <Grid item xs>
                    {isSubmitting && (
                        <Box data-testid="loading">
                            <CircularProgress size="1.5rem" />
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <MuiButton
                        data-testid="cancel"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        {msgCancel}
                    </MuiButton>
                    <MuiButton
                        data-testid="submit"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {msgCopy}
                    </MuiButton>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
        />
    );
};

CopyIsoForm.propTypes = {
    selectedItem: PropTypes.object,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

CopyIsoForm.defaultProps = {
    selectedItem: null,
    onSubmit: noop,
    onClose: noop,
};

export default CopyIsoForm;
