import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { noop } from 'lodash';

import { Typography, IconButton, MuiDialogTitle } from './mui';

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: {
        margin: 0,
        padding: spacing(3),
    },
    closeButton: {
        position: 'absolute',
        right: spacing(1),
        top: spacing(2),
        color: palette.grey[500],
    },
}));

const DialogTitle = ({ children, disabled, onClose, ...rest }) => {
    const classes = useStyles();

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...rest}>
            <Typography variant="h6">{children}</Typography>

            {onClose && (
                <IconButton
                    aria-label="close"
                    disabled={disabled}
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </MuiDialogTitle>
    );
};

DialogTitle.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onClose: PropTypes.func,
};

DialogTitle.defaultProps = {
    children: null,
    disabled: false,
    onClose: noop,
};

export default DialogTitle;
