import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Help as HelpIcon } from '@material-ui/icons';

import {
    Box,
    Menu,
    MenuItem,
    IconButton,
    MuiHyperlink,
    ListItemText,
    Tooltip,
} from '../../components/mui';
import { messages as commonMessages } from '../constants';
import { defaultMenuProps } from './UserMenu';

const { msgDocumentation, msgSupportTicket } = commonMessages;

const items = [
    {
        id: 'docs',
        name: msgDocumentation,
        url: 'https://orkadocs.macstadium.com/docs',
    },
    {
        id: 'tickets',
        name: msgSupportTicket,
        url: 'https://portal.macstadium.com',
    },
];

const useStyles = makeStyles({
    help: {
        color: 'inherit',
    },
});

const HelpMenu = () => {
    const classes = useStyles();
    const [anchorEl, setanchorEl] = useState(null);

    const handleClick = (event) => {
        setanchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setanchorEl(null);
    };

    const handleMenuItemClick = () => {
        setanchorEl(null);
    };

    const menuOpen = Boolean(anchorEl);

    return (
        <Box display="flex" alignItems="center">
            <Tooltip title="Help">
                <IconButton
                    data-testid="help"
                    aria-controls="help-menu"
                    aria-haspopup="true"
                    aria-label="Open Help Menu"
                    component={MuiHyperlink}
                    onClick={handleClick}
                    target="_blank"
                    rel="noopener"
                    className={classes.help}
                >
                    <HelpIcon />
                </IconButton>
            </Tooltip>

            <Menu
                {...defaultMenuProps}
                id="help-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
            >
                {items.map(({ id, name, url }) => (
                    <MenuItem
                        key={id}
                        data-testid={id}
                        href={url}
                        onClick={handleMenuItemClick}
                        color="inherit"
                        component={MuiHyperlink}
                        target="_blank"
                        rel="noopener"
                    >
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default HelpMenu;
