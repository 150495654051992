/* eslint react/no-array-index-key: "off" */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Typography, Tooltip } from '../../components/mui';

import Button from './Button';

const DropdownButton = ({ title, children, menuItems, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dropdownOpen = Boolean(anchorEl);
    const id = dropdownOpen ? 'dropdown' : undefined;

    return (
        <>
            <Button title={title} onClick={handleClick} {...rest}>
                {children}
            </Button>

            <Menu
                keepMounted
                id={id}
                data-testid="dropdown"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={dropdownOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: '12.5rem',
                    },
                }}
            >
                {menuItems &&
                    menuItems.map(
                        (
                            {
                                onClick,
                                dataTestId,
                                tooltip = '',
                                title: menuItemTitle,
                                color = 'initial',
                                disabled = false,
                            },
                            index,
                        ) => {
                            const handleMenuItemClick = () => {
                                handleClose();
                                onClick();
                            };

                            return (
                                <Tooltip key={index} title={tooltip}>
                                    <div>
                                        <MenuItem
                                            key={menuItemTitle}
                                            disabled={disabled}
                                            onClick={handleMenuItemClick}
                                            data-testid={dataTestId}
                                        >
                                            <Typography color={color}>
                                                {menuItemTitle}
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                </Tooltip>
                            );
                        },
                    )}
            </Menu>
        </>
    );
};

DropdownButton.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func,
            dataTestId: PropTypes.string,
            color: PropTypes.string,
            title: PropTypes.string,
            tooltip: PropTypes.string,
            disabled: PropTypes.bool,
        }),
    ),
};

DropdownButton.defaultProps = {
    children: 'DropdownButton',
    title: '',
    menuItems: [],
};

export default DropdownButton;
