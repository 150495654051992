import React from 'react';

import LoginForm from './components/LoginForm';
import Error from '../../components/Error';
import { handleError } from '../../utils/handleError';
import useUser from '../../context/useUser';

const Login = () => {
    const {
        loginUser: { mutate: mutateLoginUser, isLoading, isError, error },
    } = useUser();

    const handleSubmit = (values) => {
        mutateLoginUser(values);
    };

    return (
        <LoginForm isSubmitting={isLoading} onSubmit={handleSubmit}>
            {isError && <Error message={handleError(error)} />}
        </LoginForm>
    );
};

export default Login;
