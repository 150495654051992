import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get, noop, lowerCase } from 'lodash';

import Dialog from '../../../components/Dialog';
import DeployConfigForm from './DeployConfigForm';
import { handleError } from '../../../utils/handleError';
import { handleSuccess } from '../../../utils/handleSuccess';
import { messages } from '../constants';
import { useDeployConfig } from '../hooks';
import { useNodes } from '../../nodes/hooks';
import { NODE_STATES } from '../../nodes/enums';

const { msgDeployConfig, msgDeployed } = messages;
const showError = (err) => toast.error(handleError(err));

const DeployConfigDialog = ({
    configs,
    gpuEnabled,
    selectedItem,
    isOpen,
    onClose,
}) => {
    const queryClient = useQueryClient();

    const { data: nodesData } = useNodes({
        onError: showError,
    });

    // mutations
    const mutateProps = {
        onError: showError,
        onSettled: () => {
            queryClient.invalidateQueries('vms');
        },
    };
    const { mutate: mutateDeployVm, isLoading } = useDeployConfig({
        ...mutateProps,
        onSuccess: (res) => {
            onClose();
            toast.success(handleSuccess(res, msgDeployed));
        },
    });

    // actions
    const handleDeployConfig = async ({
        name,
        node,
        gpuPassthrough,
        vncConsole,
        isSiliconImage,
    }) => {
        await mutateDeployVm({
            name,
            node,
            gpuPassthrough,
            vncConsole,
            isSiliconImage,
        });
    };

    const handleClose = () => {
        onClose();
    };

    const nodes = get(nodesData, 'items', []);
    const mappedNodes = nodes.map(({ name, phase }) => ({
        name,
        value: name,
        isReady: lowerCase(phase) === NODE_STATES.READY,
    }));

    return (
        <Dialog
            isSubmitting={isLoading}
            isOpen={isOpen}
            title={msgDeployConfig}
            onClose={handleClose}
        >
            <DeployConfigForm
                selectedItem={selectedItem}
                configs={configs}
                nodes={[
                    {
                        name: 'None',
                        value: '',
                        isReady: true,
                    },
                    ...mappedNodes,
                ]}
                onClose={handleClose}
                onSubmit={handleDeployConfig}
                gpuEnabled={gpuEnabled}
                isSubmitting={isLoading}
            />
        </Dialog>
    );
};

DeployConfigDialog.propTypes = {
    configs: PropTypes.arrayOf(PropTypes.object).isRequired,
    gpuEnabled: PropTypes.bool,
    selectedItem: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

DeployConfigDialog.defaultProps = {
    gpuEnabled: false,
    selectedItem: null,
    isOpen: false,
    onClose: noop,
};

export default DeployConfigDialog;
