import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Popover, Box } from '../../components/mui';
import Button from './Button';

const PopoverButton = ({ title, children, popoverContent, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.preventDefault(true);
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClick = /* istanbul ignore next */ (event) => {
        event.stopPropagation();
    };

    const handlePopoverClose = (event) => {
        event.preventDefault(true);
        event.stopPropagation();
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const id = popoverOpen ? 'popover' : undefined;

    return (
        <>
            <Button title={title} onClick={handleClick} {...rest}>
                {children}
            </Button>

            <Popover
                id={id}
                data-testid="popover"
                anchorEl={anchorEl}
                open={popoverOpen}
                onClose={handlePopoverClose}
                onClick={handlePopoverClick}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: '25rem',
                    },
                }}
            >
                <Box p={2}>{popoverContent}</Box>
            </Popover>
        </>
    );
};

PopoverButton.propTypes = {
    children: PropTypes.node,
    popoverContent: PropTypes.node,
    title: PropTypes.string,
};

PopoverButton.defaultProps = {
    children: 'Poporver Button',
    popoverContent: null,
    title: '',
};

export default PopoverButton;
