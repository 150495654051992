import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get, noop } from 'lodash';

import { Typography } from '../../../components/mui';
import Dialog from '../../../components/Dialog';
import SaveVmImageForm from './SaveVmImageForm';
import { handleError } from '../../../utils/handleError';
import { handleSuccess } from '../../../utils/handleSuccess';
import { messages } from '../constants';
import { useSaveVmImage } from '../hooks';
import { useImages } from '../../images/hooks';

const {
    msgSaveVmImage,
    msgSavedVmImage,
    msgSaveVmImageInfo,
    msgSaveVmImageInfo2,
} = messages;
const showError = (err) => toast.error(handleError(err));

const SaveVmImageDialog = ({ selectedItem, isOpen, onClose }) => {
    const queryClient = useQueryClient();

    // queries
    const { data } = useImages({
        onError: showError,
    });

    // mutations
    const mutateProps = {
        onError: showError,
        onSettled: () => {
            queryClient.invalidateQueries('vms');
            queryClient.invalidateQueries('images');
        },
    };
    const { mutate: mutateSaveVmImage, isLoading } = useSaveVmImage({
        ...mutateProps,
        onSuccess: (res) => {
            onClose();
            toast.success(handleSuccess(res, msgSavedVmImage));
        },
    });

    // actions
    const handleSaveVmImage = async ({ newName }) => {
        await mutateSaveVmImage({
            name: get(selectedItem, 'name', ''),
            newName,
        });
    };

    const handleClose = () => {
        onClose();
    };

    const images = get(data, 'images', []);

    return (
        <Dialog
            isSubmitting={isLoading}
            isOpen={isOpen}
            title={msgSaveVmImage}
            onClose={handleClose}
        >
            <Typography paragraph>{msgSaveVmImageInfo}</Typography>
            <Typography paragraph>{msgSaveVmImageInfo2}</Typography>

            <SaveVmImageForm
                selectedItem={selectedItem}
                images={images}
                onClose={handleClose}
                onSubmit={handleSaveVmImage}
            />
        </Dialog>
    );
};

SaveVmImageDialog.propTypes = {
    selectedItem: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

SaveVmImageDialog.defaultProps = {
    selectedItem: null,
    isOpen: false,
    onClose: noop,
};

export default SaveVmImageDialog;
