import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import {
    Login,
    Register,
    Overview,
    NodesPage,
    Nodes,
    NodeDetails,
    VmsPage,
    Vms,
    VmDetails,
    VmConfigs,
    VmConfigDetails,
    ImagesPage,
    Images,
    ImageDetails,
    IsosPage,
    Isos,
    IsoDetails,
} from './pages';
import Layout from './pages/components/layouts/Layout';
import AuthLayout from './pages/components/layouts/AuthLayout';
import useUser from './context/useUser';
import pages from './pages/pages';

const AuthRoutes = () => (
    <Routes>
        <Route path={pages.home.path} element={<Layout />}>
            <Route
                index
                element={<Navigate to={pages.overview.path} replace />}
            />
            <Route path={pages.overview.path} element={<Overview />} />
            <Route path={pages.nodes.path} element={<NodesPage />}>
                <Route index element={<Nodes />} />
                <Route
                    path={pages.nodeDetails.path}
                    element={<NodeDetails />}
                />
            </Route>
            <Route path={pages.vmConfigs.path} element={<VmsPage />}>
                <Route index element={<VmConfigs />} />
                <Route
                    path={pages.vmConfigDetails.path}
                    element={<VmConfigDetails />}
                />
            </Route>
            <Route path={pages.vms.path} element={<VmsPage />}>
                <Route index element={<Vms />} />
                <Route path={pages.vmDetails.path} element={<VmDetails />} />
            </Route>
            <Route path={pages.images.path} element={<ImagesPage />}>
                <Route index element={<Images />} />
                <Route
                    path={pages.imageDetails.path}
                    element={<ImageDetails />}
                />
            </Route>
            <Route path={pages.isos.path} element={<IsosPage />}>
                <Route index element={<Isos />} />
                <Route path={pages.isoDetails.path} element={<IsoDetails />} />
            </Route>

            <Route
                path={pages.register.path}
                element={<Navigate to={pages.home.path} replace />}
            />
            <Route
                path={pages.login.path}
                element={<Navigate to={pages.home.path} replace />}
            />
            <Route path="*" element={<Navigate to={pages.overview.path} />} />
        </Route>

        <Route path="*" element={<Navigate to={pages.overview.path} />} />
    </Routes>
);

const UnauthRoutes = () => (
    <Routes>
        <Route path={pages.home.path} element={<AuthLayout />}>
            <Route index element={<Navigate to={pages.login.path} />} />
            <Route path={pages.register.path} element={<Register />} />
            <Route path={pages.login.path} element={<Login />} />
            <Route path="*" element={<Navigate to={pages.login.path} />} />
        </Route>

        <Route path="*" element={<Navigate to={pages.login.path} />} />
    </Routes>
);

const AppRoutes = () => {
    const { user } = useUser();

    return user && user.authenticated ? <AuthRoutes /> : <UnauthRoutes />;
};

export default AppRoutes;
