import { includes } from 'lodash';

export const validators = {
    isUniqueImageName: (value, items) => !includes(items, value),
};

export const messages = {
    msgImages: 'Images',
    msgImagesInfo:
        'Orka images are disk images that represent VM storage. Base images are bootable disk images that provide the OS, file system, and storage for your VM configs and VMs. Empty images provide added storage or serve as the base image during manual OS installs from ISO.',
    msgEmptyImages: `You don't seem to have any images available. Try pulling from the remote repo.`,
    msgImagesHint:
        'Orka images are shared. All users can view and use them to create VM configs and deploy VMs.',
    msgImageDetails: 'Image Details',
    msgTabGeneral: 'General',
    msgDelete: 'Delete',
    msgDeleted: 'Image successfully deleted!',
    msgConfirmDelete: (name) =>
        `This will delete the selected image (<b>${name}</b>). You will no longer be able to deploy VM configs that reference this image. VMs that use this base image will become unresponsive.<br/><br/>
        This operation cannot be reversed.`,
    msgConfirmOverwrite: (name) =>
        `The provided name (<b>${name}</b>) is already in use. This will overwrite the existing image of that name and will result in loss of data. This might corrupt any VM configs and VMs that are using the existing image. This operation cannot be reversed.`,
    msgGenerate: 'Generate',
    msgCopy: 'Copy',
    msgRename: 'Rename',
    msgPull: 'Pull',
    msgPullRemote: 'Pull Remote',
    msgGenerated: 'Image successfully generated!',
    msgCopied: 'Image successfully copied!',
    msgPulled: 'Remote image successfully pulled!',
    msgInvalidUniqueImageName: 'This name is already in use.',
};
