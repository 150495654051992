import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Help as HelpIcon } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Box, Tabs, Tab, Paper, Tooltip } from '../../components/mui';
import PageTitle from '../components/PageTitle';
import Toolbar from '../../components/Toolbar';
import Loader from '../../components/Loader';
import RefreshButton from '../components/RefreshButton';
import BackButton from '../components/BackButton';
import NodesList from './components/NodesList';
import PortsList from './components/PortsList';
import VmsList from './components/VmsList';
import TabPanel from '../components/TabPanel';
import pages from '../pages';
import { messages } from './constants';
import { useNodes } from './hooks';
import { useFeatures } from '../hooks';
import { useVms } from '../vms/hooks';
import { handleError } from '../../utils/handleError';
import {
    getSelectedNodes,
    getSelectedPorts,
    getSelectedVms,
} from './selectors';

const {
    msgNodeDetails,
    msgTabGeneral,
    msgTabReservedPorts,
    msgTabReservedPortsInfo,
    msgTabVms,
} = messages;

const NodeDetails = () => {
    const { nodeId } = useParams();

    const [selectedTab, setSelectedTab] = useState(0);
    const [gpuEnabled, setGPUEnabled] = useState(false);
    const queryClient = useQueryClient();

    // queries
    const { data: features } = useFeatures();

    const { data: nodes, isFetching } = useNodes({
        onError: (err) => toast.error(handleError(err)),
    });
    const { data: vms, isFetching: isVmsFetching } = useVms({
        onError: (err) => toast.error(handleError(err)),
    });

    const selectedNodes = getSelectedNodes(nodes, [nodeId]);

    const selectedVms = getSelectedVms(vms, [nodeId]);

    const selectedPorts = getSelectedPorts(selectedVms, [nodeId]);

    useEffect(() => {
        if (features && features.gpuPassthroughEnabled) {
            setGPUEnabled(true);
        }
    }, [features]);

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
    };

    const handleRefresh = () => {
        queryClient.invalidateQueries('nodes');
        queryClient.invalidateQueries('ports');
        queryClient.invalidateQueries('vms');
    };

    return (
        <>
            <BackButton
                routerTo={pages.nodes.path}
                routerOptions={{ state: { selectedId: nodeId } }}
            />

            <PageTitle
                primaryText={msgNodeDetails}
                secondaryText={nodeId}
                noBottomMargin
            />

            <Toolbar rightContent={<RefreshButton onClick={handleRefresh} />} />

            <Paper elevation={1}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab label={msgTabGeneral} />
                    <Tab
                        label={
                            <Tooltip title={msgTabReservedPortsInfo}>
                                <Box display="flex">
                                    {msgTabReservedPorts}
                                    <Box ml={1}>
                                        <HelpIcon fontSize="small" />
                                    </Box>
                                </Box>
                            </Tooltip>
                        }
                    />
                    <Tab label={msgTabVms} />
                </Tabs>

                <TabPanel value={selectedTab} index={0}>
                    <NodesList items={selectedNodes} gpuEnabled={gpuEnabled} />
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                    <PortsList items={selectedPorts} />
                </TabPanel>

                <TabPanel value={selectedTab} index={2}>
                    <VmsList items={selectedVms} />
                </TabPanel>
            </Paper>

            {/* TODO: Consider adding "isFeaturesFetching" condition */}
            {(isFetching || isVmsFetching) && <Loader />}
        </>
    );
};

export default NodeDetails;
