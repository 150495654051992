import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { Grid, Box } from './mui';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    leftContent: {
        '& .MuiButton-root': {
            marginRight: spacing(2),
            marginBottom: spacing(2),
        },
    },
    rightContent: {
        '& .MuiButton-root': {
            marginRight: spacing(2),
            marginBottom: spacing(2),
        },

        [breakpoints.up('sm')]: {
            justifyContent: 'flex-end',

            '& .MuiButton-root': {
                marginLeft: spacing(2),
                marginRight: 0,
            },
        },
    },
}));

const Toolbar = ({ leftContent, rightContent }) => {
    const classes = useStyles();

    return (
        <Box mb={2}>
            <Grid container spacing={0}>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={8}
                    lg={8}
                    className={classes.leftContent}
                >
                    {leftContent}
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    className={classes.rightContent}
                >
                    {rightContent}
                </Grid>
            </Grid>
        </Box>
    );
};

Toolbar.propTypes = {
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
};

Toolbar.defaultProps = {
    leftContent: null,
    rightContent: null,
};

export default Toolbar;
