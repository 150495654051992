import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get, noop } from 'lodash';

import Dialog from '../../../components/Dialog';
import PullRemoteIsoForm from './PullRemoteIsoForm';
import { handleError } from '../../../utils/handleError';
import { handleSuccess } from '../../../utils/handleSuccess';
import { messages } from '../constants';
import { useIsos, useRemoteIsos, usePullRemoteIso } from '../hooks';
import { getIsos } from '../selectors';

const { msgPullRemote, msgPulled } = messages;
const showError = (err) => toast.error(handleError(err));

const PullRemoteIsoDialog = ({ isOpen, onClose }) => {
    const queryClient = useQueryClient();

    // queries
    const { data: isosData } = useIsos({
        onError: showError,
    });
    const { data: remoteIsosData } = useRemoteIsos({
        onError: showError,
    });

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('isos');
        },
    };
    const { mutate: mutatePullRemoteIso, isLoading } = usePullRemoteIso({
        ...mutateProps,
        onSuccess: (res) => {
            onClose();
            toast.success(handleSuccess(res, msgPulled));
        },
    });

    // actions
    const handlePullIso = async ({ iso, newName }) => {
        await mutatePullRemoteIso({
            iso,
            newName,
        });
    };

    const handleClose = () => {
        onClose();
    };

    const isos = getIsos(isosData).map((i) => i.name);
    const remoteIsos = get(remoteIsosData, 'items', []).map((iso) => iso.name);

    return (
        <Dialog
            isSubmitting={isLoading}
            isOpen={isOpen}
            title={`${msgPullRemote} ISO`}
            onClose={handleClose}
        >
            <PullRemoteIsoForm
                isos={isos}
                remoteIsos={remoteIsos}
                onClose={handleClose}
                onSubmit={handlePullIso}
            />
        </Dialog>
    );
};

PullRemoteIsoDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

PullRemoteIsoDialog.defaultProps = {
    isOpen: false,
    onClose: noop,
};

export default PullRemoteIsoDialog;
