import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { noop } from 'lodash';

import { MuiDialog, DialogContent } from './mui';
import DialogTitle from './DialogTitle';

const Dialog = ({
    isOpen,
    isSubmitting,
    title,
    onClose,
    children,
    ...rest
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOnClose = (event, reason) => {
        if (isSubmitting) {
            return;
        }
        onClose(event, reason);
    };

    return (
        <MuiDialog
            {...rest}
            open={isOpen}
            fullScreen={fullScreen}
            onClose={handleOnClose}
        >
            {title && (
                <DialogTitle
                    disabled={isSubmitting}
                    title={title}
                    onClose={onClose}
                >
                    {title}
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>
        </MuiDialog>
    );
};

Dialog.propTypes = {
    isOpen: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    title: PropTypes.node,
    children: PropTypes.node,
    onClose: PropTypes.func,
};

Dialog.defaultProps = {
    isOpen: false,
    isSubmitting: false,
    title: null,
    children: null,
    onClose: noop,
};

export default Dialog;
