import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { get, noop } from 'lodash';

import Dialog from '../../../components/Dialog';
import PullRemoteImageForm from './PullRemoteImageForm';
import { handleError } from '../../../utils/handleError';
import { handleSuccess } from '../../../utils/handleSuccess';
import { messages } from '../constants';
import { useRemoteImages, useImages, usePullRemoteImage } from '../hooks';

const { msgPullRemote, msgPulled } = messages;
const showError = (err) => toast.error(handleError(err));

const PullRemoteImageDialog = ({ isOpen, onClose }) => {
    const queryClient = useQueryClient();

    // queries
    const { data: imagesData } = useImages({
        onError: showError,
    });
    const { data: remoteImagesData } = useRemoteImages({
        onError: showError,
    });

    // mutations
    const mutateProps = {
        onError: (err) => toast.error(handleError(err)),
        onSettled: () => {
            queryClient.invalidateQueries('images');
        },
    };
    const { mutate: mutatePullRemoteImage, isLoading } = usePullRemoteImage({
        ...mutateProps,
        onSuccess: (res) => {
            onClose();
            toast.success(handleSuccess(res, msgPulled));
        },
    });

    // actions
    const handlePullImage = async ({ image, newName }) => {
        await mutatePullRemoteImage({
            image,
            newName,
        });
    };

    const handleClose = () => {
        onClose();
    };

    const images = get(imagesData, 'items', []).map((image) => image.name);
    const remoteImages = get(remoteImagesData, 'items', []).map(
        (image) => image.name,
    );

    return (
        <Dialog
            isSubmitting={isLoading}
            isOpen={isOpen}
            title={`${msgPullRemote} Image`}
            onClose={handleClose}
        >
            <PullRemoteImageForm
                images={images}
                remoteImages={remoteImages}
                onClose={handleClose}
                onSubmit={handlePullImage}
                isSubmitting={isLoading}
            />
        </Dialog>
    );
};

PullRemoteImageDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

PullRemoteImageDialog.defaultProps = {
    isOpen: false,
    onClose: noop,
};

export default PullRemoteImageDialog;
