import React from 'react';
import PropTypes from 'prop-types';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { noop } from 'lodash';

import Button from './Button';
import { messages as commonMessages } from '../constants';

const { msgRefresh } = commonMessages;

const RefreshButton = ({ onClick, ...rest }) => (
    <Button
        aria-label={msgRefresh}
        data-testid="refresh"
        title={msgRefresh}
        variant="contained"
        onClick={onClick}
        {...rest}
    >
        <RefreshIcon />
    </Button>
);

RefreshButton.propTypes = {
    onClick: PropTypes.func,
};

RefreshButton.defaultProps = {
    onClick: noop,
};

export default RefreshButton;
