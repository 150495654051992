import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Box } from './mui';
import logoImgSrc from '../images/orka-logo.png';

const useStyles = makeStyles(({ typography }) => ({
    logo: {
        height: typography.pxToRem(78),
        transform: `translateX(-${typography.pxToRem(5)})`,
    },
}));

const Logo = () => {
    const classes = useStyles();

    return (
        <Box mb={3}>
            <img
                className={classes.logo}
                src={logoImgSrc}
                alt="Orca whale logo"
            />
        </Box>
    );
};

export default Logo;
