import React from 'react';
import PropTypes from 'prop-types';
import {
    Formik,
    Form as FormikForm,
    Field as FormikField,
    ErrorMessage as FormikErrorMessage,
} from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles, FormHelperText } from '@material-ui/core';
import { omit, noop } from 'lodash';

import { Box, MuiButton, Grid } from './mui';

const blacklist = ['initialValue', 'hide', 'initialTouched'];

const getInitialValues = (fields) =>
    fields &&
    fields.reduce(
        (obj, { name, initialValue }) => ({
            ...obj,
            [name]: initialValue,
        }),
        {},
    );

const getInitialTouched = (fields) =>
    fields &&
    fields.reduce(
        (obj, { name, initialTouched = false }) => ({
            ...obj,
            [name]: initialTouched,
        }),
        {},
    );

const useStyles = makeStyles(({ typography, breakpoints }) => ({
    form: {
        minWidth: typography.pxToRem(400),
        [breakpoints.down('xs')]: {
            minWidth: 'auto',
        },
    },
    formContent: {
        [breakpoints.down('sm')]: {
            padding: 0,
        },
    },
}));

const Form = ({
    children,
    fields,
    onSubmit,
    onValidate,
    formContentRenderer,
    formLeftTitleContentTemplate,
}) => {
    const classes = useStyles();

    return (
        <Formik
            enableReinitialize
            initialValues={getInitialValues(fields)}
            // Allows validation to show up immediately on field change for checkboxes
            initialTouched={getInitialTouched(fields)}
            validate={onValidate}
            onSubmit={onSubmit}
        >
            {(props) => (
                <FormikForm translate="no" className={classes.form}>
                    <Box
                        className={classes.formContent}
                        p={formLeftTitleContentTemplate ? 5 : 0}
                    >
                        <Grid container>
                            {formLeftTitleContentTemplate && (
                                <Grid item xs={12} sm={12} md={4}>
                                    {formLeftTitleContentTemplate}
                                </Grid>
                            )}

                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={formLeftTitleContentTemplate ? 8 : 12}
                            >
                                {fields &&
                                    fields
                                        .filter(({ hide }) => !hide)
                                        .map(
                                            ({
                                                component = TextField,
                                                name,
                                                label = 'label',
                                                type = 'text',
                                                variant = 'outlined',
                                                fieldContent,
                                                shouldShowError = false,
                                                disabled = false,
                                                ...rest
                                            }) => (
                                                <div key={name}>
                                                    <FormikField
                                                        margin="normal"
                                                        component={component}
                                                        id={name}
                                                        name={name}
                                                        type={type}
                                                        label={label}
                                                        variant={variant}
                                                        disabled={disabled}
                                                        data-testid={`${name}-field`}
                                                        {...omit(
                                                            rest,
                                                            blacklist,
                                                        )}
                                                    />
                                                    {fieldContent}
                                                    {
                                                        // Not all MUI controls have helperText and error props
                                                        shouldShowError && (
                                                            <FormikErrorMessage
                                                                name={name}
                                                            >
                                                                {(msg) => (
                                                                    <FormHelperText
                                                                        error
                                                                        margin="dense"
                                                                        style={{
                                                                            marginLeft:
                                                                                '15px',
                                                                            marginTop:
                                                                                '-5px',
                                                                        }}
                                                                        data-testid={`${name}-error`}
                                                                    >
                                                                        {msg}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormikErrorMessage>
                                                        )
                                                    }
                                                </div>
                                            ),
                                        )}

                                {children}
                            </Grid>
                        </Grid>
                    </Box>

                    {formContentRenderer && formContentRenderer(props)}
                </FormikForm>
            )}
        </Formik>
    );
};

Form.propTypes = {
    children: PropTypes.node,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.elementType,
            name: PropTypes.string.isRequired,
            type: PropTypes.oneOf([
                'text',
                'email',
                'password',
                'number',
                'checkbox',
            ]),
            label: PropTypes.string,
            variant: PropTypes.string,
            initialValue: PropTypes.any.isRequired,
            fieldContent: PropTypes.node,
            disabled: PropTypes.bool,
        }),
    ),
    formContentRenderer: PropTypes.func,
    formLeftTitleContentTemplate: PropTypes.node,
    onSubmit: PropTypes.func,
    onValidate: PropTypes.func,
};

Form.defaultProps = {
    children: undefined,
    fields: [
        {
            name: 'name',
            initialValue: 'value',
        },
    ],
    // eslint-disable-next-line react/prop-types
    formContentRenderer: ({ isSubmitting }) => (
        <Box mt={2} mb={1}>
            <MuiButton
                data-testid="submit"
                type="submit"
                variant="contained"
                color="primary"
                title="Submit"
                disabled={isSubmitting}
            >
                Submit
            </MuiButton>
        </Box>
    ),
    formLeftTitleContentTemplate: undefined,
    onSubmit: noop,
    onValidate: noop,
};

export default Form;
