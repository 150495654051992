import React from 'react';
import PropTypes from 'prop-types';
import { noop, get, includes } from 'lodash';

import {
    Grid,
    MenuItem,
    MuiButton,
    Box,
    CircularProgress,
    DialogContentText,
} from '../../../components/mui';
import Form from '../../../components/Form';
import Hint from '../../components/Hint';
import useConfirm from '../../../context/useConfirm';
import { messages as commonMessages } from '../../constants';
import { messages, validators } from '../constants';
import { formatHtml } from '../../../utils/formatHtml';

const { msgRequired, msgCancel, msgLongerOperationHint } = commonMessages;
const { msgPull, msgInvalidIsoName, msgConfirmOverwrite } = messages;
const { isValidIsoName } = validators;

const PullRemoteIsoForm = ({ isos, remoteIsos, onSubmit, onClose }) => {
    const confirm = useConfirm();

    const handleValidate = (values) => {
        const errors = {};

        if (!values.iso) {
            errors.iso = msgRequired;
        }

        if (values.newName && !isValidIsoName(values.newName)) {
            errors.newName = msgInvalidIsoName;
        }

        return errors;
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = async (values) => {
        let isConfirmed = true;

        const isoRemoteName = get(values, 'iso', '');
        const isoNewName = get(values, 'newName', '');

        if (
            (isoNewName && includes(isos, isoNewName)) ||
            (!isoNewName && includes(isos, isoRemoteName))
        ) {
            isConfirmed = await confirm({
                description: (
                    <DialogContentText
                        dangerouslySetInnerHTML={formatHtml(
                            msgConfirmOverwrite(isoNewName || isoRemoteName),
                        )}
                    />
                ),
            });
        }

        if (isConfirmed) {
            await onSubmit(values);
        }
    };

    const fields = [
        {
            select: true,
            name: 'iso',
            label: 'ISO *',
            SelectProps: {
                SelectDisplayProps: {
                    'data-testid': 'iso',
                },
            },
            children:
                remoteIsos &&
                remoteIsos.map((iso) => (
                    <MenuItem key={iso} value={iso}>
                        {iso}
                    </MenuItem>
                )),
            initialValue: '',
        },
        {
            name: 'newName',
            label: 'New Name (optional)',
            initialValue: '',
        },
    ];

    const formContentRenderer = ({ isSubmitting }) => (
        <Box mt={2} mb={1}>
            <Box mb={2}>
                <Hint variant="warning" fullWidth>
                    {msgLongerOperationHint}
                </Hint>
            </Box>
            <Grid container alignItems="center">
                <Grid item xs>
                    {isSubmitting && (
                        <Box data-testid="loading">
                            <CircularProgress size="1.5rem" />
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <MuiButton
                        data-testid="cancel"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                    >
                        {msgCancel}
                    </MuiButton>
                    <MuiButton
                        data-testid="submit"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {msgPull}
                    </MuiButton>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Form
            fields={fields}
            onValidate={handleValidate}
            onSubmit={handleSubmit}
            formContentRenderer={formContentRenderer}
        />
    );
};

PullRemoteIsoForm.propTypes = {
    isos: PropTypes.arrayOf(PropTypes.string),
    remoteIsos: PropTypes.arrayOf(PropTypes.string),
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

PullRemoteIsoForm.defaultProps = {
    isos: [],
    remoteIsos: [],
    onSubmit: noop,
    onClose: noop,
};

export default PullRemoteIsoForm;
