import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, alpha } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';

import { Drawer, Hidden, IconButton, Divider } from './mui';

import orkaLogoSrc from '../images/orka-logo.png';

export const sidebarWidth = 250;

const useStyles = makeStyles(({ mixins, spacing, breakpoints, palette }) => ({
    root: {
        position: 'relative',
        [breakpoints.up('sm')]: {
            width: sidebarWidth,
            flexShrink: 0,
        },
    },
    sidebarHeader: {
        ...mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        padding: spacing(0, 1),
        justifyContent: 'space-between',
    },
    sidebarFooter: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: spacing(1),
        color: '#fff',
    },
    spacer: mixins.toolbar,
    sidebarPaper: {
        width: sidebarWidth,
        background: palette.storm.main,
        color: '#fff',
    },
    logoWrapper: {
        padding: spacing(2),
    },
    logo: {
        width: spacing(8),
    },
    divider: {
        background: alpha('#fff', 0.08),
    },
}));

const Sidebar = ({
    isMenuOpen,
    onMenuToggle,
    sidebarContent,
    sidebarFooterContent,
}) => {
    const classes = useStyles();

    const handleMenuToggle = () => {
        onMenuToggle();
    };

    const renderSiderbarContent = () => (
        <>
            <div className={classes.logoWrapper}>
                <Link to="/">
                    <img
                        src={orkaLogoSrc}
                        alt="MacStadium Orka orca whale logo"
                        className={classes.logo}
                    />
                </Link>
            </div>
            <Divider classes={{ root: classes.divider }} variant="middle" />
            {sidebarContent}
        </>
    );

    const renderSiderbarMobileContent = () => (
        <>
            <div className={classes.sidebarHeader}>
                <Link to="/">
                    <img
                        src={orkaLogoSrc}
                        alt="MacStadium Orka orca whale logo"
                        className={classes.logo}
                    />
                </Link>
                <IconButton
                    color="inherit"
                    data-testid="close-sidebar"
                    aria-label="Close menu"
                    onClick={handleMenuToggle}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider classes={{ root: classes.divider }} />

            {sidebarContent}
        </>
    );

    const renderSidebarFooterContent = () => (
        <div className={classes.sidebarFooter}>{sidebarFooterContent}</div>
    );

    return (
        <nav className={classes.root} aria-label="pages">
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    open={isMenuOpen}
                    onClose={handleMenuToggle}
                    classes={{
                        paper: classes.sidebarPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {renderSiderbarMobileContent()}
                    {renderSidebarFooterContent()}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    variant="permanent"
                    open
                    classes={{
                        paper: classes.sidebarPaper,
                    }}
                >
                    {renderSiderbarContent()}
                    {renderSidebarFooterContent()}
                </Drawer>
            </Hidden>
        </nav>
    );
};

Sidebar.propTypes = {
    isMenuOpen: PropTypes.bool,
    onMenuToggle: PropTypes.func,
    sidebarContent: PropTypes.node,
    sidebarFooterContent: PropTypes.node,
};

Sidebar.defaultProps = {
    isMenuOpen: false,
    onMenuToggle: noop,
    sidebarContent: null,
    sidebarFooterContent: null,
};

export default Sidebar;
