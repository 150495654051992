import { format, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

const dateError = 'N/A';
const dateFormat = 'MM/dd/yyyy hh:mm:ss';

export const formatDate = (value) => {
    if (!value) {
        return dateError;
    }

    let formattedDate;

    try {
        formattedDate = format(parseISO(value), dateFormat, {
            locale: enUS,
        });
    } catch (e) {} // eslint-disable-line no-empty

    if (!formattedDate) {
        return dateError;
    }

    return formattedDate;
};
