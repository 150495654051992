import { includes, filter, get } from 'lodash';

import { getVms } from '../vms/selectors';

import { findItemByKeyValue } from '../../utils/findItemByKeyValue';
import { extractVmPorts } from '../../utils/extractVmPorts';

export const getNodes = (data) => get(data, 'items', []);

export const getSelectedNodes = (data, ids) =>
    filter(getNodes(data), (item) => includes(ids, get(item, 'name', '')));

export const getSelectedPorts = (data, ids) =>
    filter(data, (item) => includes(ids, get(item, 'node', '')))
        .map((item) => extractVmPorts(item))
        .flat()
        .sort((a, b) => a.hostPort - b.hostPort);

export const getSelectedVms = (data, ids) =>
    filter(getVms(data), (item) => includes(ids, get(item, 'node', '')));

export const getUpdatedSelectedNode = (nodes, selectedId) =>
    findItemByKeyValue(nodes, 'name', selectedId);
