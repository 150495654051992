import React from 'react';
import PropTypes from 'prop-types';
import { Airplay as AirPlayIcon } from '@material-ui/icons';
import { includes, get } from 'lodash';

import { MuiHyperlink, Typography } from '../../../components/mui';
import PopoverButton from '../../components/PopoverButton';

const ConnectionButton = ({ item }) => {
    const ip = get(item, 'ip');
    const vncPort = get(item, 'vnc');
    const screenSharingPort = get(item, 'screenshare');
    const sshPort = get(item, 'ssh');

    const hasVnc = !includes(vncPort, 'N/A');

    return (
        <PopoverButton
            isIcon
            title="Connection"
            size="small"
            popoverContent={
                <>
                    {hasVnc && (
                        <Typography paragraph>
                            For VNC, use:
                            <br />
                            <b>{` ${ip}:${vncPort}`}</b>
                        </Typography>
                    )}
                    <Typography paragraph>
                        For Apple Screen Share (only if already enabled on the
                        VM), use:
                        <br />
                        <MuiHyperlink
                            href={`vnc://${ip}:${screenSharingPort}`}
                            target="_blank"
                        >
                            <b>{` vnc://${ip}:${screenSharingPort}`}</b>
                        </MuiHyperlink>
                    </Typography>
                    <Typography paragraph>
                        For SSH (only if already enabled on the VM), use:
                        <br />
                        <b>{` ssh <vm_user>@${ip} -p ${sshPort} `}</b>
                    </Typography>
                </>
            }
        >
            <AirPlayIcon />
        </PopoverButton>
    );
};

ConnectionButton.propTypes = {
    item: PropTypes.object.isRequired,
};

export default ConnectionButton;
