const stormGray = {
    50: '#f9fafb', // '#f8f8f9'
    100: '#f0f1f7', // '#f0f1f2'
    200: '#e5e6eB', // '#dadbdf'
    300: '#d1d3db', // '#c4c5cc'
    400: '#9c9faf', // '#979aa6'
    500: '#6b6e80',
    600: '#4b4f63', // '#606373'
    700: '#373b51', // '#505360'
    800: '#1f2337', // '#40424d'
    900: '#111427', // '#34363f'
};

const azureRadiance = {
    50: '#f2f8ff',
    100: '#e6f2ff',
    200: '#bfdeff',
    300: '#99caff',
    400: '#4da3ff',
    500: '#007bff',
    600: '#006fe6',
    700: '#005cbf',
    800: '#004a99',
    900: '#003c7d',
};

const royalBlue = {
    50: '#f7f8ff',
    100: '#eff2ff',
    200: '#d7ddff',
    300: '#bfc9ff',
    400: '#8fa1ff',
    500: '#5f78ff',
    600: '#566ce6',
    700: '#475abf',
    800: '#394899',
    900: '#2f3b7d',
};

const butterflyBush = {
    50: '#f8f7fb',
    100: '#f1eef6',
    200: '#dcd5e9',
    300: '#c7bbdc',
    400: '#9e88c1',
    500: '#7455a7',
    600: '#684d96',
    700: '#57407d',
    800: '#463364',
    900: '#392a52',
};

const carnation = {
    50: '#fef6f7',
    100: '#fdedee',
    200: '#fad2d5',
    300: '#f7b7bb',
    400: '#f18289',
    500: '#eb4c56',
    600: '#d4444d',
    700: '#b03941',
    800: '#8d2e34',
    900: '#73252a',
};

const sorbus = {
    50: '#fff9f3',
    100: '#fff2e8',
    200: '#ffdfc4',
    300: '#fecba1',
    400: '#fea55b',
    500: '#fd7e14',
    600: '#e47112',
    700: '#be5f0f',
    800: '#984c0c',
    900: '#7c3e0a',
};

const amber = {
    50: '#fffcf3',
    100: '#fff9e6',
    200: '#fff0c1',
    300: '#ffe69c',
    400: '#ffd451',
    500: '#ffc107',
    600: '#e6ae06',
    700: '#bf9105',
    800: '#997404',
    900: '#7d5f03',
};

const apple = {
    50: '#f4fbf6',
    100: '#eaf6ec',
    200: '#c9e9d1',
    300: '#a9dcb5',
    400: '#69c17d',
    500: '#28a745',
    600: '#24963e',
    700: '#1e7d34',
    800: '#186429',
    900: '#145222',
};

export default {
    gray: stormGray,
    blue: azureRadiance,
    indigo: royalBlue,
    purple: butterflyBush,
    red: carnation,
    orange: sorbus,
    yellow: amber,
    green: apple,
};
